@import '../../foundation/variable';
@import '../../foundation/mixin';

.c-titl {
  @include c-titl;
  &_left{
    @include c-titl;
    padding-left: 0.7em;
    &::before{
      content: "";
      position: absolute;
      top: .25em;
      left: 0;
      display: block;
      width: 3px;
      height: 1em;
      background: $navy;
    }
  }
  &-border-bottom {
    font-weight: 700;
    letter-spacing: 0.05em;
    padding-bottom: 0.3em;
    border-bottom: 1px solid $black;
    &-main {
      border-bottom: 1px solid $main;
    }
  }
  &-check {
    position: relative;
    display: inline-block;
    font-weight: 700;
    letter-spacing: 0.08em;
    padding-left: 1.3em;
    &::before {
      content: '';
      position: absolute;
      display: block;
      top: 0.35em;
      left: 0.25em;
      width: 0.5em;
      height: 0.75em;
      border-style: solid;
      border-width: 0 0.21em 0.21em 0;
      border-color: $main;
      transform: rotate(45deg);
    }
    &.color-red {
      &::before {
        border-color: $red;
      }
    }
    &.color-grn {
      &::before {
        border-color: $grn;
      }
    }
    &.color-blue {
      &::before {
        border-color: $blue;
      }
    }
    &-r {
      position: relative;
      display: inline-block;
      font-weight: 700;
      letter-spacing: 0.08em;
      padding-left: 1.3em;
      a {
        color: $black;
        font-weight: 700;
        letter-spacing: 0.08em;
        transition: 0.3s;
        &:hover {
          color: $main;
        }
      }
      &.color-navy {
        .i-check {
          background: $navy;
        }
        a {
          &:hover {
            color: $navy;
          }
        }
      }
      &.color-red {
        .i-check {
          background: $red;
        }
        a {
          &:hover {
            color: $red;
          }
        }
      }
      &.color-blue {
        .i-check {
          background: $blue;
        }
        a {
          &:hover {
            color: $blue;
          }
        }
      }
      &.color-grn {
        .i-check {
          background: $grn;
        }
        a {
          &:hover {
            color: $grn;
          }
        }
      }
      .i-check {
        position: absolute;
        top: 0.3em;
        left: 0;
        width: 1em;
        height: 1em;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50px;
        background: $main;
        &::before {
          content: '';
          position: relative;
          display: block;
          width: 0.55em;
          height: 0.5em;
          background: url(/dcms_media/image/arr_white.png) no-repeat center/contain;
        }
      }
    }
  }
}
.c-leadtxt {
  @include sp {
    font-size: 15px;
    line-height: 1.4;
  }
  font-size: 18px;
  line-height: 1.8;
}
.c-balloon {
  @include pc {
    padding: 18px 30px;
  }
  border-radius: 15px 15px 0 15px;
  padding: 20px 40px;
  background: $white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.c-catch {
  color: $main;
  font-size: 24px;
  line-height: 1.2;
  font-family: 'Roboto', sans-serif;
}
.c-bd-left {
  position: relative;
  height: 100%;
  .c-titl {
    position: relative;
    padding-left: 1em;
    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 8px;
      height: 100%;
      border-radius: 10px;
      background: $main;
    }
  }
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 3.5px;
    width: 1px;
    height: 100%;
    // border-left: 2px dotted $main;
    background-image: linear-gradient(to bottom, $main, $main 5px, transparent 5px, transparent 10px);
    background-size: 5px 10px;
    background-position: left top;
    background-repeat: repeat-y;
  }
}
