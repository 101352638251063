@import '../foundation/variable';
@import '../foundation/mixin';

body.recruit-index {
  .l-hdd {
    // background: transparent;
  }
  #locator ol {
    padding-top: 0;
    border-top: none;
  }
}
.recruit-top {
  position: relative;
  background: #e4e5e9;
  &::after {
    content: '';
    position: absolute;
    top: 30%;
    left: 0;
    display: block;
    width: 100%;
    height: 70%;
    //   background: $main;
    background-image: radial-gradient(#c8c8fc 7%, transparent 8%), radial-gradient(#c8c8fc 7%, transparent 8%);
    background-size: 38px 38px;
    background-position: 0px 0, 38px 38px;
    z-index: 0;
  }
  .inner {
    @include lg {
      gap: 40px;
    }
    @include tb {
      gap: 30px;
    }
    @include sp {
      gap: 10px;
    }
    display: flex;
    align-items: center;
    gap: 60px;
  }
  &-img {
    @include xlg {
      width: 40%;
    }
    @include pc {
      width: 38%;
    }
    position: relative;
    max-width: 600px;
    width: calc(50% - 30px);
    align-self: flex-end;
    z-index: 1;
  }
  &-titlarea {
    max-width: 570px;
    width: auto;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
    .baloon {
      @include lg {
        padding: 45px;
      }
      @include pc {
        padding: 40px;
      }
      @include tb {
        padding: 30px;
      }
      @include sp {
        padding: 15px;
        height: auto;
        aspect-ratio: auto;
        border-radius: 15px;
        border: 4px solid $main;
        margin-bottom: 10px;
      }
      position: relative;
      padding: 40px;
      border-radius: 30px;
      width: 100%;
      height: auto;
      // aspect-ratio: 1.58/1;
      margin-bottom: 80px;
      border: 8px solid $main;
      &::before,
      &::after {
        @include sp {
          display: none;
        }
        content: '';
        position: absolute;
        left: 50%;
        bottom: 0px;
        display: block;
        width: 260px;
        height: 65px;
        transform: translate(-50%, 100%);
      }
      &::before {
        width: 130px;
        background: #e4e5e9;
      }
      &::after {
        background: url(/dcms_media/other/img_baloon.svg) no-repeat center/auto;
      }
    }
    .c-titl {
      @include lg {
        font-size: calc(1.375rem + 2.4vw);
      }
      @include pc {
        font-size: calc(1.375rem + 2.2vw);
      }
      @include sp {
        font-size: 18px;
      }
      font-size: 48px;
      font-weight: 500;
      &.-en {
        @include lg {
          font-size: 36px;
        }
        @include tb {
          font-size: 24px;
        }
        @include sp {
          font-size: 16px;
        }
      }
    }
    .txt-main {
      display: block;
    }
    .c-leadtxt {
      @include tb {
        font-size: 90%;
      }
      @include sp {
        font-size: 70%;
        margin-bottom: 10px;
      }
    }
  }
  &-bottom {
    @include tb {
      padding: 20px 0;
    }
    position: relative;
    padding: 40px 0;
    text-align: center;
    background: #a0a0fb;
    z-index: 1;
    .c-titl {
      @include sp {
        font-size: 20px;
      }
      font-size: 2.133rem;
      font-weight: 500;
    }
    .txt {
      margin-top: 10px;
    }
  }
}
