@import '../../foundation/variable';
@import '../../foundation/mixin';

.u-flex {
  display: flex $imp;
  flex-wrap: wrap;
  @include tb {
    &-tb {
      display: flex $imp;
    }
  }
  @include sp {
    &-sp {
      display: flex $imp;
    }
  }
}
@for $i from 2 through 8 {
  .u-flex {
    &#{$i} {
      display: flex $imp;
      flex-wrap: wrap;
      .col {
        width: calc(100% / $i);
      }
    }
    @include tb {
      &-tb {
        &#{$i} {
          display: flex $imp;
          .col {
            width: calc(100% / $i);
          }
        }
      }
    }
    @include sp {
      &-sp {
        &#{$i} {
          display: flex $imp;
          .col {
            width: calc(100% / $i);
          }
        }
      }
    }
  }
}

@for $i from 10 through 100 {
  .u-flex {
    @for $c from 1 through 8 {
      &#{$c} {
        @include sp {
          &-sp {
            &.gap#{$i}-sp {
              width: 100%;
              display: flex $imp;
              flex-wrap: wrap;
              gap: 1px * $i $imp;
              .col {
                $gapwidth: 100% / $c;
                $gapcol: ($i / $c) * ($c - 1);
                width: calc((#{$gapwidth} - #{$gapcol}px) * 1) + $imp;
              }
            }
          }
        }
        @include tb {
          &-tb {
            &.gap#{$i}-tb {
              width: 100%;
              display: flex $imp;
              flex-wrap: wrap;
              gap: 1px * $i $imp;
              .col {
                $gapwidth: 100% / $c;
                $gapcol: ($i / $c) * ($c - 1);
                width: calc((#{$gapwidth} - #{$gapcol}px) * 1) + $imp;
              }
            }
          }
        }
        &.gap#{$i} {
          width: 100%;
          display: flex $imp;
          flex-wrap: wrap;
          gap: 1px * $i;
          .col {
            $gapwidth: 100% / $c;
            $gapcol: ($i / $c) * ($c - 1);
            width: calc((#{$gapwidth} - #{$gapcol}px) * 1);
          }
          @for $l from 1 through 11 {
            @include tb {
              .col-tb-#{$l} {
                width: calc(($l / 12) * 100% - (#{$i}px / #{$c} * (#{$c} - 1))) + $imp;
              }
            }
            @include sp {
              .col-sp-#{$l} {
                width: calc(($l / 12) * 100% - (#{$i}px / #{$c} * (#{$c} - 1))) + $imp;
              }
            }
            .col-#{$l} {
              width: calc(($l / 12) * 100% - ((#{$i}px / #{$c}) * (#{$c} - 1)));
            }
          }
          @include tb {
            .col-tb-12 {
              flex: 0 0 100%;
              width: 100% + $imp;
            }
          }
          @include sp {
            .col-sp-12 {
              flex: 0 0 100%;
              width: 100% + $imp;
            }
          }
          .col-12 {
            flex: 0 0 100%;
            width: 100%;
          }
        }
      }
    }
  }
}

.c-flex {
  @include sp {
    gap: 30px;
  }
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  .col {
    @include sp {
      width: 100%;
    }
    width: calc(50% - 20px);
    padding: $col-padding;
    border-radius: $col-border-radius;
    box-shadow: $col-box-shadow;
    background: $white;
  }
}

/* FLEX プロパティ */
.jc_sb {
  justify-content: space-between;
}
.jc_start {
  justify-content: flex-start;
}
.jc_c {
  justify-content: center;
}
.jc_end {
  justify-content: flex-end;
}
.ai_start {
  align-items: flex-start;
}
.ai_c {
  align-items: center;
}
.ai_b {
  align-items: baseline;
}
.ai_end {
  align-items: flex-end;
}
