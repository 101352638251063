@import '../../foundation/variable';
@import '../../foundation/mixin';

.u-list {
  &-none {
    display: flex;
    flex-wrap: wrap;
    gap: 0.65em;
    list-style-type: none;
  }
  &-decimal {
    display: flex;
    flex-direction: column;
    gap: 0.65em;
    list-style-type: decimal;
    padding-inline-start: 1.3em;
    margin-left: 0.3em;
  }
  &-decimal-2 {
    counter-reset: count 0;
    display: flex;
    flex-direction: column;
    gap: 0.65em;
    list-style-type: decimal;
    padding-inline-start: 1.3em;
    margin-left: 0.3em;
    li {
      list-style-type: none;
      text-indent: -1.55em;
      &::before {
        content: counter(count) ') ';
        counter-increment: count 1;
      }
    }
  }
  &-decimal-3 {
    counter-reset: count 0;
    display: flex;
    flex-direction: column;
    gap: 0.65em;
    list-style-type: decimal;
    padding-inline-start: 1.3em;
    margin-left: 0.3em;
    li {
      list-style-type: none;
      text-indent: -1.55em;
      &::before {
        content: '(' counter(count) ')';
        counter-increment: count 1;
      }
    }
  }
  &-disk {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    gap: 0.65em;
    line-height: 1.6;
    padding-inline-start: 0;
    li {
      position: relative;
      padding-inline-start: 1.3em;
      &::before {
        content: '';
        position: absolute;
        top: 10px;
        left: 3px;
        display: block;
        width: 5px;
        height: 5px;
        background: $black;
        border-radius: 20px;
      }
    }
    &-row {
      list-style-type: none;
      display: flex;
      flex-wrap: wrap;
      gap: 0.65em 1em;
      line-height: 1.6;
      padding-inline-start: 0;
      li {
        position: relative;
        padding-inline-start: 1.3em;
        &::before {
          content: '';
          position: absolute;
          top: 10px;
          left: 3px;
          display: block;
          width: 5px;
          height: 5px;
          background: $black;
          border-radius: 20px;
        }
      }
    }
  }
  &-check {
    display: flex;
    flex-direction: column;
    gap: 0.65em;
    list-style-type: none;
    padding-inline-start: 0;
    li {
      position: relative;
      padding-inline-start: 1.3em;
      &::before {
        content: '';
        position: absolute;
        top: 0.25em;
        left: 0.25em;
        display: block;
        width: 0.5em;
        height: 0.75em;
        border-right: 0.21em solid $main;
        border-bottom: 0.21em solid $main;
        transform: rotate(45deg);
      }
    }
    &.color-red {
      li {
        &::before {
          border-right: 0.21em solid $red;
          border-bottom: 0.21em solid $red;
        }
      }
    }
    &.color-grn {
      li {
        &::before {
          border-right: 0.21em solid $grn;
          border-bottom: 0.21em solid $grn;
        }
      }
    }
    &.color-blue {
      li {
        &::before {
          border-right: 0.21em solid $blue;
          border-bottom: 0.21em solid $blue;
        }
      }
    }
  }
  &-circle {
    display: flex;
    flex-direction: column;
    gap: 0.65em;
    list-style-type: circle;
    padding-inline-start: 1.3em;
  }
}
.aclink {
  @include sp {
    gap: 15px;
  }
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px 30px;
  li {
    list-style-type: none;
    font-size: 18px;
    font-weight: 700;
    a {
    }
  }
}
