@import '../foundation/variable';
@import '../foundation/mixin';

body.home {
  background-image: url(/dcms_media/other/index_bg.svg), url(/dcms_media/other/index_bg.svg);
  background-position: left -10vw top 110vh, left 10vw top 250vh;
  background-size: auto;
  background-repeat: no-repeat, no-repeat, no-repeat;
}
body.is-fixed {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
}
.section-titl {
  @include sp {
    gap: 3px;
  }
  display: flex;
  flex-direction: column;
  gap: 10px;
  // align-items: flex-start;
  .c-catch {
    @include sp {
      font-size: 18px;
    }
  }
  .c-titl {
    @include sp {
      font-size: 32px;
    }
    font-size: 42px;
    font-weight: 400;
    line-height: 1.3;
  }
  .c-bttn {
    margin-top: 5px;
  }
}
.l-mainvisual {
  @include pc {
    height: 38vh;
  }
  @include sp {
    height: auto;
    padding: 30px 0;
  }
  position: relative;
  height: 65vh;
  margin-top: 140px;
  .inner {
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
  }
  &-u-flex {
    @include sp {
      gap: 15px;
    }
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    .c-bttn {
      a {
        width: 210px;
      }
    }
  }
  &__bg {
    @include tb {
      top: -90px;
      left: 32vw;
    }
    @include sp {
      top: 20px;
      left: 160vw;
    }
    position: absolute;
    top: -140px;
    left: 49vw;
    max-width: 1200px;
    width: 100%;
    transform: translateX(-100%);
    z-index: -1;
  }
  &__titlarea {
    @include tb {
      width: 80%;
    }
    @include sp {
      width: 83%;
      margin: 0;
    }
    max-width: 800px;
    width: 65%;
    margin-left: auto;
    overflow: hidden;
  }
  &__c-titl {
    &.-justify {
      @include sp {
        text-align: justify;
      }
    }
    @include sp {
      font-size: 7.5vw;
      letter-spacing: 0.01em;
    }
    font-size: clamp(2rem, 1.698rem + 1.51vw, 3rem);
    font-weight: 400;
    margin-bottom: 20px;
  }
}
.c-service {
  @include tb {
    margin-bottom: 80px;
  }
  position: relative;
  margin-bottom: 200px;
  z-index: 1;
  .inner {
    @media only screen and (max-width: 1500px) {
      padding: 40px 3vw;
    }
    @include tb {
      padding: 40px 30px;
    }
    @include sp {
      padding: 0;
      background: transparent;
      box-shadow: none;
    }
    padding: 60px;
    border-radius: 8px;
    background: $white;
    box-shadow: 0 20px 60px rgba(0, 0, 0, 0.1);
  }
  .u-flex2 {
    @include pc {
      gap: 30px;
    }
    gap: 60px;
    .col {
      @include pc {
        width: calc(50% - 15px);
      }
      @include tb {
        width: 100%;
      }
      @include sp {
        width: 100%;
        background: $white;
        padding: 25px 20px;
        border-radius: 8px;
        box-shadow: 0 20px 60px rgba(0, 0, 0, 0.1);
      }
      width: calc(50% - 30px);
    }
  }
  &-c-titl {
    @include lg {
      font-size: calc(18px + 0.72vw);
    }
    @include tb {
      font-size: calc(15px + 0.6vw);
      font-size: 14px;
      margin-bottom: 15px;
    }
    @include sp {
      text-align: left;
    }
    font-size: 28px;
    font-weight: 400;
    text-align: center;
    margin-bottom: 40px;
  }
  &-c-sub-titl {
    @media only screen and (max-width: 1600px) {
      font-size: calc(24px + 0.6vw);
    }
    @include lg {
      font-size: calc(18px + 0.7vw);
    }
    @include tb {
      font-size: 28px;
      text-align: center;
    }
    font-size: 42px;
  }
  .u-flex {
    @media only screen and (max-width: 1400px) {
      flex-direction: column;
      gap: 10px;
    }
    @include tb {
      margin-top: 20px;
      margin-bottom: 20px;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
    @include sp {
      margin-top: 10px;
      margin-bottom: 30px;
    }
    margin-top: 20px;
    margin-bottom: 50px;
    justify-content: space-between;
    .c-bttn {
    }
    &.-colmn {
      @include tb {
        align-items: center;
      }
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
    }
  }
  dl {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;
    & + dl {
      margin-top: 30px;
    }
    &:hover {
      dd {
        // opacity: 1;
        // transform: translateY(-100%);
        // visibility: visible;
      }
    }
    dt {
      @include sp {
        font-size: 21px;
      }
      font-size: 24px;
      a {
        display: block;
      }
    }
    dd {
      &::after {
        // content: '';
        position: absolute;
        left: 4px;
        bottom: -6px;
        display: block;
        width: 18px;
        height: 10px;
        background: $main;
        clip-path: polygon(0 0, 100% 0%, 50% 100%);
      }
    }
  }
}

.c-blog {
  @include pc {
    padding: 40px 0 120px;
  }
  @include tb {
    margin-bottom: 180px;
  }
  @include sp {
    padding: 40px 0;
    margin-bottom: 80px;
  }
  position: relative;
  padding: 0 0 120px;
  background: $white;
  margin-bottom: 200px;
  &::before,
  &::after {
    @include sp {
      display: none;
    }
    content: '';
    position: absolute;
    bottom: 0;
    height: calc(100% + 40vh);
    display: block;
    background: $navy;
    z-index: 0;
  }
  &::before {
    @media only screen and (max-width: 1280px) {
      width: 7vw;
    }
    @include pc {
      display: none;
    }
    right: 50vw;
    width: 200px;
  }
  &::after {
    @include pc {
      width: 40vw;
    }
    @include tb {
      width: 30vw;
    }
    right: 0;
    width: 50vw;
  }
  .inner {
    @include xlg {
      flex-direction: column;
      justify-content: center;
      gap: 40px;
    }
    @include sp {
      gap: 20px;
    }
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 1;
  }
  .section-titl {
    @include xlg {
      width: 100%;
    }
    width: 350px;
    width: 30%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
    .c-titl {
      white-space: nowrap;
    }
  }
  &--right {
    @include xlg {
      width: 100%;
      max-width: 100%;
    }
    max-width: 780px;
    width: 63%;
    &.splide {
      visibility: visible;
    }
  }
  .splide__track {
    @include sp {
      // overflow: hidden;
    }
    overflow: visible;
  }
  .splide__list {
    @include sp {
      gap: 0;
      flex-wrap: nowrap;
    }
    position: relative;
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    z-index: 1;
  }
  .splide__slide {
    @include sp {
      width: calc(100% - 60px) !important;
      margin: 0 30px !important;
    }
    list-style-type: none;
    width: calc(50% - 20px);
    border-radius: 8px;
    background: $white;
    box-shadow: 0 20px 60px rgba(0, 0, 0, 0.1);
    transition: 0.3s;
    overflow: hidden;
    &:nth-child(even) {
      @include sp {
        top: 0;
      }
      position: relative;
      top: 60px;
    }
    &:hover {
      transform: translateY(-3px);
      box-shadow: 0 20px 60px rgba(0, 0, 0, 0.3);
    }
  }
  .blog-image {
    overflow: hidden;
  }
  .blog-name {
    color: $main;
  }
  .blog-data {
    padding: 30px 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  time {
    display: none;
  }
  .catelist {
    display: none;
  }
  .htitle {
    font-size: 18px;
    line-height: 1.6;
    font-weight: 700;
    a {
      display: inline-block;
      text-decoration: none;
      color: $black;
    }
  }
  .c-linker {
    position: relative;
    color: $black;
    text-align: right;
    text-decoration: underline;
    &::after {
      content: '';
      width: 9px;
      height: 9px;
      display: inline-block;
      border-style: solid;
      border-width: 0 2px 2px 0;
      border-color: transparent $black $black transparent;
      margin-left: 6px;
      transform: rotate(-45deg);
    }
  }
  .c-btm {
    @include pc {
      margin-top: 40px;
      display: block;
      @include sp {
        margin-top: 0;
      }
    }
    margin-top: 60px;
    display: none;
  }
  .splide__arrow {
    top: calc(50% - 63px);
  }
  .splide__pagination {
    margin-top: 15px;
    position: static;
  }
  .splide__pagination__page {
    opacity: 1;
  }
  .splide__pagination__page.is-active {
    background: $main;
  }
}
.c-about {
  @include tb {
    margin-bottom: 180px;
  }
  @include sp {
    margin-bottom: 80px;
  }
  position: relative;
  margin-bottom: 300px;
  &::before {
    @include lg {
      width: 50vw;
    }
    @include tb {
      top: 50%;
      height: 130%;
      transform: translateY(-50%);
    }
    @include sp {
      display: none;
    }
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: calc(50vw + 200px);
    height: 100%;
    background: $navy;
    transform: translateY(100px);
    z-index: -1;
  }
  .inner {
    @include tb {
      gap: 30px;
    }
    @include sp {
      flex-direction: column;
    }
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    gap: 6vw;
  }
  &-grid {
    @include tb {
      gap: 25px;
    }
    max-width: 860px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    gap: 50px;
    .col {
      @media only screen and (max-width: 1250px) {
        width: auto;
      }
      width: 440px;
      padding: 20px;
      grid-column: 1/2;
      grid-row: 1/4;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
      background: $white;
      box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
      transition: 0.6s;
      &:hover {
        box-shadow: 0 30px 40px rgba(0, 0, 0, 0.4);
        transform: translateY(-5px);
      }
      &:nth-child(2) {
        grid-column: 1/2;
        grid-row: 4/7;
      }
      &:last-child {
        @media only screen and (max-width: 1250px) {
          width: 28vw;
          height: 28vw;
        }
        @include tb {
          width: auto;
          height: auto;
        }
        width: 370px;
        height: 370px;
        grid-column: 2/3;
        grid-row: 2/6;
      }
    }
    &-icon {
      @include tb {
        width: 40px;
      }
    }
  }
  .section-titl {
    @include sp {
      align-items: center;
    }
  }
  .c-bttn {
    @include sp {
      margin-top: 15px;
    }
  }
  .c-titl {
    line-height: 1;
    white-space: nowrap;
    text-align: center;
    a {
      @include tb {
        font-size: 18px;
      }
      font-size: 24px;
      font-weight: 500;
      color: $black;
      text-decoration: none;
    }
    .-sub {
      @include sp {
        font-size: 80%;
      }
    }
  }
}
.c-rss {
  @include lg {
    margin-bottom: 180px;
  }
  @include sp {
    margin-bottom: 160px;
  }
  position: relative;
  margin-bottom: 240px;
  &::after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    bottom: 100px;
    width: 100%;
    height: calc(100% + 100px);
    background: $white;
    transform: translateY(100%);
    z-index: -3;
  }
  .rss_box {
    position: relative;
    padding: 40px 30px;
    margin: 30px auto;
    background: $white;
    border-radius: 8px;
    box-shadow: 0 20px 60px rgba(0, 0, 0, 0.1);
    z-index: 1;
  }
  .rss_box-bg {
    position: absolute;
    top: 0;
    left: 50%;
    max-width: 1380px;
    width: 100%;
    height: 86%;
    transform: translateX(-50%);
    z-index: -1;
    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
      z-index: -1;
    }
    &::before {
      left: -60px;
      bottom: 0;
      width: 100%;
      height: 360px;
      background: $navy;
      transform: translateY(50%);
    }
    &::after {
      left: 0;
      bottom: 0;
      width: calc(100% + 100px);
      height: calc(60% + 80px);
      background: $black;
      transform: translateY(80px);
      z-index: -2;
    }
  }
}
.c-bnr {
  @include lg {
  }
  @include sp {
  }
  background: $white;
  .inner {
    @include tb {
      gap: 40px;
    }
    @include sp {
      gap: 30px;
    }
    display: flex;
    flex-wrap: wrap;
    gap: 60px;
  }
  .col {
    @include sp {
      width: 100%;
    }
    position: relative;
    padding: 30px;
    width: calc(50% - 30px);
    aspect-ratio: 3 / 1;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0);
    overflow: hidden;
    transition: 0.3s;
    .Img {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &:hover {
      box-shadow: 0 30px 40px rgba(0, 0, 0, 0.25);
      transform: translateY(-5px);
      &::before {
        backdrop-filter: blur(5px) brightness(1.05);
      }
    }
    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: transparent;
      backdrop-filter: blur(0px);
      transition: 0.3s;
      z-index: 1;
    }
  }
  .c-titl {
    z-index: 1;
    a {
      font-size: 32px;
      font-size: calc(1.325rem + 0.9vw);
      font-weight: 500;
      line-height: 1;
      color: $black;
      display: block;
      text-decoration: none;
      // text-shadow: 1px 3px 2px #fff;
      text-shadow: #fff 2px 0px, #fff -2px 0px, #fff 0px -2px, #fff 0px 2px, #fff 2px 2px, #fff -2px 2px, #fff 2px -2px, #fff -2px -2px, #fff 1px 2px,
        #fff -1px 2px, #fff 1px -2px, #fff -1px -2px, #fff 2px 1px, #fff -2px 1px, #fff 2px -1px, #fff -2px -1px;
      filter: drop-shadow(0 0 3px #fff);
    }
  }
}
