@import '../foundation/variable';
@import '../foundation/mixin';
.media {
  .p-cv {
    &::before {
      background: $white;
    }
    &::after {
      background: $lightgray;
    }
  }
  .l-footer {
    background: $white;
  }
}

body.media {
  background: #f5f5f5;
}
p {
  margin-bottom: 0;
}
ul,
ol {
  margin: 0;
  padding: 0;
}
a {
  color: #333;
}
a:hover {
  color: #322c90;
}
time {
  display: flex;
  align-items: center;
  gap: 6px;
  letter-spacing: 0.15em;
}
time::before {
  content: '';
  display: inline-flex;
  width: 12px;
  height: 12px;
  background: url(../image/img-time.png) no-repeat center / contain;
}

@media only screen and (max-width: 690px) {
  time {
    font-size: 10px;
  }
}

#main a[id] {
  position: relative;
  display: block;
  height: 0;
  top: -100px;
}

main.inner {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 80px;
}

@media only screen and (min-width: 980px) {
  #contents_left {
    width: calc(100% - 260px - 40px);
  }

  #contents_right {
    width: 260px;
  }
}
@media only screen and (max-width: 980px) {
  #contents_left {
    width: 100%;
  }

  #contents_right {
    width: 100%;
    margin-top: 50px;
  }
}

/* article list **********************************/
article {
}
.article-list {
  display: flex;
  border-radius: 8px;
  /* border: 1px solid #ddd; */
  background: #fff;
  margin-bottom: 30px;
  box-shadow: 0 20px 40px rgb(0 0 0 / 6%);
  overflow: hidden;
}
.article-list .post_thumbnail {
  width: 320px;
  /* height: 160px; */
  /* overflow: hidden; */
  margin: 0;
}
@media only screen and (max-width: 1024px) {
  .article-list .post_thumbnail {
    width: 240px;
  }
}
.article-list .post_thumbnail a {
  display: block;
  width: 100%;
  height: 100%;
}
.article-list .post_thumbnail img {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
}
.article-list .info {
  flex: 1;
  padding: 15px;
}
.article-list .info .d-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
}
.article-list .info .taglist a {
  font-size: 12px;
}
.article-list .info time {
  display: none;
  font-size: 10px;
}
.article-list .title {
  font-size: 20px;
  line-height: 1.4;
  margin-bottom: 0.5em;
}
.article-list .title a {
  display: inline-block;
  font-size: 20px;
  line-height: 1.4;
  text-decoration: none;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}
@media only screen and (max-width: 980px) {
  .article-list .title a {
    font-size: 18px;
  }
}
@media only screen and (max-width: 690px) {
  .article-list {
    flex-direction: column;
  }
  .article-list .post_thumbnail {
    width: 100%;
    /* height: 120px; */
  }
  .article-list .info {
    flex: initial;
  }
}

/*index-list*/
.index-list {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  li {
    @media only screen and (max-width: 1280px) {
      width: calc(50% - 15px);
    }
    @include sp {
      width: 100%;
    }
    width: calc(33.33% - 20px);
    border-radius: 8px;
    /* border: 1px solid #ddd; */
    transition: 0.3s;
    background: #fff;
    cursor: pointer;
    &:hover {
      transform: translateY(-5px);
    }
    .blog-image {
      @media only screen and (max-width: 1280px) {
        height: 150px;
      }
      height: 190px;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .blog-data {
      padding: 25px;
      .d-flex {
        display: flex;
        justify-content: space-between;
      }
      time {
        display: none;
        line-height: 1;
        position: relative;
        font-size: 11px;
      }
      .taglist {
        a {
          font-size: 13px;
        }
      }
      .htitle {
        @include sp {
          margin: 6px 0 6px;
          font-size: 15px;
          line-height: 1.4;
        }
        font-size: 18px;
        /* font-weight: bold; */
        line-height: 1.4;
        margin: 8px 0 15px;
        &:hover {
          text-decoration: none;
        }
        a {
          display: block;
          text-decoration: unset;
          &:hover {
            text-decoration: none;
          }
        }
      }
      .btxt {
        display: none;
      }
    }
  }
}

/* SNS **********************************/
.sns_share {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}
.sns_share.foot {
  margin-top: 30px;
}
.sns_share li {
  position: relative;
  list-style: none;
}
.sns_share .line {
  display: none;
}
.sns_share .fb {
  top: -1px;
}
.sns_share .line a {
  display: block;
  font-size: 11px;
  text-align: center;
}

@media only screen and (max-width: 690px) {
  article .info .sns_share {
    display: none;
  }
}

/* article-base  */
.article-base {
  border-radius: 8px;
  background: #fff;
}
.article-inner {
  padding: 40px;
}
@media only screen and (max-width: 690px) {
  .article-inner {
    padding: 15px;
  }
}
.article-base .catelist {
  margin-bottom: 15px;
}
.article-base {
  margin-bottom: 10px;
  box-shadow: 0 20px 40px rgb(0 0 0 / 6%);
}
.article-base .c-title {
  border-bottom: 3px solid #574fc2;
  padding-bottom: 5px;
  margin-bottom: 5px;
}
.article-base time {
  display: none;
  justify-content: flex-end;
  font-size: 13px;
}

.cover {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  overflow: hidden;
}

.cover img {
  max-width: 100%;
  width: 100%;
  height: auto;
  display: block;
  margin: auto;
}
h1,
h2,
h3,
h4,
h5 {
  font-weight: 500 !important;
}
/*#main{
  padding: 20px;
  border-width: 1px;
  border-style: solid;
  line-height: 2;
  font-size: 15px;
  @media only screen and (max-width:690px) {
    padding: 0;
    border: none;
  }
  h1{
    font-size: 30px;
    line-height: 1.5;
    margin: 10px 0 20px;
      @media only screen and (max-width:690px) {
        font-size: 24px;
        margin-bottom: 15px;
      }
  }
  .taglist{
    margin: 5px 0 16px;
  }
  h2{
    font-size: 26px;
    line-height: 1.5;
    padding-bottom: 10px;
    margin-bottom: 20px;
    border-width: 0 0 3px 0;
    border-style: solid;
    @media only screen and (max-width:690px) {
      font-size: 24px;
    }
  }
  h3{
    font-size: 22px;
    line-height: 1.5;
    padding-left: 12px;
    margin-bottom: 16px;
    border-width: 0 0 0 5px;
    border-style: solid;
    @media only screen and (max-width:690px) {
      font-size: 20px;
    }
  }
  h4{
    font-size: 18px;
    line-height: 1.5;
    margin-bottom: 10px;
    @media only screen and (max-width:690px) {
      font-size: 17px;
    }
  }
}*/
/* main content title **********************************/
/*BMの見出しとあわせてください。*/
/* next prev **********************************/
#nextprev {
  position: relative;
  margin-top: 20px;
  margin-bottom: 120px;
  display: inline-flex;
  gap: 80px;
}
#nextprev::after {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 1px;
  background: #ccc;
  transform: translate(-50%, -50%);
  z-index: -1;
}

#nextprev li {
  display: none;
  list-style: none;
}

#nextprev li a {
  font-size: 13px;
  font-weight: 500;
  text-decoration: none;
}

#nextprev li a:hover {
  text-decoration: none;
}

#nextprev .prev {
  position: relative;
  padding: 0 10px;
  background: #f5f5f5;
}
#nextprev .prev::before {
  content: '';
  display: block;
  position: absolute;
  top: calc(50% + 1px);
  left: 0;
  width: 8px;
  height: 8px;
  border-style: solid;
  border-color: #333;
  border-width: 0 0 2px 2px;
  transform: translateY(-50%) rotate(45deg);
}

#nextprev .next {
  position: relative;
  padding: 0 10px;
  background: #f5f5f5;
}
#nextprev .next::after {
  content: '';
  display: block;
  position: absolute;
  top: calc(50% + 1.5px);
  right: 0;
  width: 8px;
  height: 8px;
  border-style: solid;
  border-color: #333;
  border-width: 2px 2px 0 0;
  transform: translateY(-50%) rotate(45deg);
}

/* contact **********************************/
/*.contact{
  margin-top: 32px;
  .title{
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    line-height: 1;
    letter-spacing: .08em;
    padding: 15px 8px;
    @media only screen and (max-width:560px) {
      font-size: 18px;
    }
  }
  .txt{
    padding: 32px 16px;
    border-width: 0 2px 2px 2px;
    border-style: solid;
    font-size: 0;
    @media only screen and (max-width:560px) {
      padding: 26px 5%;
    }
    div{
      display: inline-block;
      text-align: center;
      width: 50%;
      font-size: 20px;
      line-height: 1;
      vertical-align: middle;
      @media only screen and (max-width:560px) {
        width: 100%;
      }
      &.tel{
        font-size: 28px;
        @media only screen and (max-width:560px) {
          margin-bottom: 16px;
        }
        span{
          background-repeat: no-repeat;
          background-position: left center;
          background-size: 24px auto;
          padding-left: 35px;
        }
      }
      &.mail{
        padding: 0 3%;
        @media only screen and (max-width:560px) {
          padding: 0 10%;
        }
        a{
          display: block;
          padding: 15px;
          border-radius: 4px;
          .in{
            background-repeat: no-repeat;
            background-position: left center;
            background-size: 24px auto;
            padding-left: 40px;
          }
        }
      }
    }
  }
}
*/
/* relation **********************************/
#relation {
}
#relation .u-flex {
  flex-wrap: wrap;
  gap: 30px;
}

#relation .relationlist {
  width: calc(50% - 15px);
  color: #333;
  padding: 20px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 20px 40px rgb(0 0 0 / 6%);
  cursor: pointer;
  transition: 0.3s;
}
#relation .relationlist:hover {
  opacity: 0.7;
}
#relation .relationlist a {
  text-decoration: none;
}

@media only screen and (max-width: 690px) {
  #relation .relationlist {
    width: 100%;
  }
}
#relation .d-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#relation .taglist {
  color: #574fc2;
}
#relation .c-title {
  font-size: 30px;
  margin-bottom: 20px;
  line-height: 1;
}

#relation:after {
  content: '';
  display: table;
  clear: both;
}

.relationlist .post_thumbnail {
  float: left;
  width: 35%;
  display: none;
}

.relationlist .post_thumbnail img {
  max-width: 100%;
  width: auto;
  height: auto;
}

.relationlist time,
.relationlist .txt {
}

.relationlist time {
  display: none;
  margin-top: -5px;
  font-size: 10px;
  letter-spacing: 0.15em;
  color: #333;
}

.relationlist .txt {
  font-size: 18px;
  font-weight: 500;
  margin-top: 8px;
  margin-bottom: 8px;
  color: #333;
}

@media only screen and (max-width: 690px) {
  .relationlist .txt {
    margin-top: 0;
  }
}

article#main > div:not(.catelist):not(.taglist) {
}

/*---------------------------
詳細ページ 見出し
----------------------------*/
#main h2 {
  font-size: calc(16px + 1.05vw);
  font-weight: 500;
  line-height: 1.4;
  padding: 0 0 10px;
  /* margin-top: 1em; */
  margin-bottom: 15px;
  border-bottom: 1px solid #322c90;
  position: relative;
  font-size: 32px;
}

#main h2:before {
  /* content: ""; */
  width: 50px;
  height: 3px;
  position: absolute;
  /* background-color: #EB530A; */
  top: -3px;
  left: 0;
}

#main h3 {
  font-size: 24px;
  font-weight: bold;
  line-height: 1.4;
  padding-left: 0.8em;
  /* margin-top: 1em; */
  margin-bottom: 1em;
  position: relative;
}
#main h3:before {
  content: '';
  width: 3px;
  height: 100%;
  position: absolute;
  background-color: #eb530a;
  top: 0;
  left: 0;
}

#main h4 {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.4;
  padding-left: 15px;
  position: relative;
  margin-bottom: 1em;
}

#main h4:before {
  content: '';
  width: 10px;
  height: 10px;
  position: absolute;
  top: 10px;
  left: 0;
  background-color: #eb530a;
}

#main h4:after {
  /* content: ""; */
  background-color: #dddddd;
  width: 3px;
  height: 80%;
  position: absolute;
  top: 20%;
  left: 3px;
}

#main h5 {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.4;
  padding-left: 15px;
  position: relative;
  margin-bottom: 1em;
}

#main h5:before {
  content: '';
  width: 3px;
  height: 80%;
  position: absolute;
  top: 0;
  left: 0;
  background: #eb530a;
}

#main h5:after {
  content: '';
  background-color: #dddddd;
  width: 3px;
  height: 80%;
  position: absolute;
  top: 20%;
  left: 3px;
}

/*---------------------------
詳細ページ 引用
----------------------------*/
blockquote {
  width: 95%;
  margin: 0 auto;
  padding: 1em 1em 1em 3em;
  position: relative;
  font-size: 90%;
}

blockquote::before {
  content: '“';
  font-size: 500%;
  line-height: 1em;
  font-family: 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', Meiryo, メイリオ, Helvetica, sans-serif;
  position: absolute;
  left: 0px;
  top: 0px;
}

/*---------------------------
詳細ページ リスト
----------------------------*/
#main ul:not(.sns_share) li {
  list-style-type: disc;
  margin-left: 20px;
  line-height: 1.6;
  margin-bottom: 5px;
}

#main .list_none li {
  list-style-type: none;
}

/*---------------------------
詳細ページ 表
----------------------------*/
#main table.tab1 {
  width: 100%;
}

#main table.tab1 th,
#main table.tab1 td {
  border: 1px solid #dddddd;
  padding: 10px;
}

#main table.tab1 th {
  background-color: #eeeeee;
}

#main table.tab2 {
  width: 100%;
}

#main table.tab2 th,
#main table.tab2 td {
  border: 1px solid #dddddd;
  padding: 10px;
}

#main table.tab2 th {
  background-color: #eeeeee;
}

#main table.tab3 {
  width: 100%;
}

#main table.tab3 th,
#main table.tab3 td {
  border: 1px solid #dddddd;
  padding: 10px;
}

#main table.tab3 th {
  background-color: #eeeeee;
}

.voice_table {
  width: 100%;
}

.voice_table th {
  width: 20%;
  background-color: #f5f5f5;
  padding: 1%;
  vertical-align: middle;
  border: 1px solid #cdcdcd;
}

.voice_table td {
  width: 80%;
  padding: 1% 2%;
  vertical-align: middle;
  border: 1px solid #cdcdcd;
  text-align: left;
}

.voice_table02 {
  width: 100%;
}

.voice_table02 th {
  width: 20%;
  padding: 5px 10px;
  vertical-align: middle;
  border: 1px solid #cdcdcd;
  font-size: 14px;
  font-weight: normal;
}

.voice_table02 td {
  width: 80%;
  padding: 5px 10px;
  vertical-align: middle;
  border: 1px solid #cdcdcd;
  text-align: left;
  font-size: 14px;
}

.voice_table03 {
  width: 100%;
}

.voice_table03 th {
  width: 30%;
  background-color: #cce9f0;
  padding: 1%;
  vertical-align: middle;
  border: 1px solid #cdcdcd;
  font-size: 16px;
}

.voice_table03 td {
  width: 35%;
  padding: 1%;
  vertical-align: middle;
  border: 1px solid #cdcdcd;
  text-align: center;
  font-size: 16px;
}

table.tab01 {
  table-layout: fixed;
}

table.tab01 {
  width: 100%;
}

table.tab01 th,
table.tab01 td {
  border: 1px solid #dddddd;
  padding: 5px;
}

table.tab01 th {
  background-color: #eeeeee;
}

@media only screen and (max-width: 690px) {
  .table_wrap {
    overflow-x: scroll;
  }

  .table_wrap table {
    width: 640px;
  }
}

/*---------------------------
詳細ページ sp　on off
----------------------------*/
.br_sp {
  display: none;
}

@media only screen and (max-width: 690px) {
  .br_sp {
    display: block;
  }
}

/*---------------------------
詳細ページ box構造
----------------------------*/
.introduction_box {
  border: 2px solid #f6f6f6;
  padding: 2%;
  margin-bottom: 15px;
}

.introduction_box_l {
  width: 29%;
  margin-right: 2%;
  float: left;
}

.introduction_box_r {
  width: 69%;
  float: right;
}

.introduction_box .bg_gray {
  background-color: #f6f6f6;
  padding: 3%;
}

@media only screen and (max-width: 690px) {
  .introduction_box_l,
  .introduction_box_r {
    width: 100%;
    margin-right: 0;
    float: none;
    margin-bottom: 10px;
  }
}

.box-quarter:before,
.box-quarter:after {
  content: '';
  display: block;
  clear: both;
}

.box-quarter {
  display: inline-block;
  width: 100%;
  text-align: center;
}

.box-quarter p {
  text-align: left;
  color: #888888;
  font-size: 11px;
}

.box-quarter img {
  margin: 0 0 5px;
}

.box-quarter_box {
  float: left;
  width: 25%;
  padding: 1%;
}

.box-quarter_box img {
  width: auto;
  max-height: 300px;
}

.box2 {
  display: inline-block;
  width: 100%;
  text-align: center;
}

.box2 p {
  color: #888888;
  font-size: 11px;
  text-align: left;
}

.box2 img {
  margin: 0 0 5px;
}

.box2_left {
  float: left;
  width: 50%;
  padding: 1%;
}

.box2_right {
  float: right;
  width: 50%;
  padding: 1%;
}

.box2_left img,
.box2_right img {
  width: auto;
  max-width: 100%;
  max-height: 350px;
}

.box-fifty:before,
.box-fifty:after {
  content: '';
  display: block;
  clear: both;
}

.box-fifty {
  display: inline-block;
  width: 100%;
}

.box-fifty p {
  color: #888888;
  font-size: 11px;
}

.box-fifty img {
  margin: 0 0 5px;
}

.box-fifty_left {
  float: left;
  width: 50%;
  padding: 1%;
}

.box-fifty_right {
  float: right;
  width: 50%;
  padding: 1%;
}

.box-fifty_left img,
.box-fifty_right img {
  width: 100%;
  height: auto;
}

.box_1_3 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.box_1_3_in {
  -webkit-box-flex: 0;
  -ms-flex: 0 1 32%;
  flex: 0 1 32%;
  width: 32%;
  margin-right: 1%;
}

.box_1_3_in:last-child {
  margin-right: 0;
}

.box_1_3_in img {
  max-width: 100%;
  height: auto;
}

@media only screen and (max-width: 690px) {
  .box_1_3_in {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 100%;
    flex: 0 1 100%;
    width: 100%;
    margin-bottom: 10px;
    margin-right: 0;
  }

  .box_1_3_in:last-child {
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 690px) {
  .box-fifty_left,
  .box-fifty_right {
    float: none;
    width: 100%;
    padding: 1%;
  }

  .box-quarter_box {
    float: none;
    width: 100%;
    padding: 1%;
  }

  .box2_left,
  .box2_right {
    float: none;
    width: 100%;
    padding: 1%;
  }
}

/*---------------------------
詳細ページ フロー
----------------------------*/
.flow_box {
  padding: 2%;
  border: 4px solid #f5f5f5;
}

.flow_box .flow_txt {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 1.5rem;
  border: 1px solid #f5f5f5;
  padding: 10px;
  margin-bottom: 35px !important;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.flow_box .flow_txt:not(:last-child)::after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 14px 10px 0 10px;
  top: calc(100% + 20px - 7px);
  left: calc(50% - 5px);
}

.flow_box .flow_txt:last-child {
  margin-bottom: 0;
}

/*---------------------------
詳細ページ iframe
----------------------------*/
#main .iframebox {
  position: relative;
  width: 100%;
  padding-top: 75%;
}

#main .iframebox iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/*---------------------------
詳細ページ free download
----------------------------*/
.bfdl_box {
  background-color: #f6f6f6;
  padding: 3%;
  margin: 30px 0 60px;
}

.bfdl_tit {
  font-size: 18px;
  font-weight: bold;
  line-height: 1.4;
  padding: 5px 0 7px 15px;
  border-left: 6px solid #000;
  border-bottom: 1px solid #000;
  margin-bottom: 20px;
}

.bfdl_1_2box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.bfdl_1_2box_in {
  -webkit-box-flex: 0;
  -ms-flex: 0 1 49%;
  flex: 0 1 49%;
  background-color: #fff;
  padding: 3%;
}

.bfdl_1_2box_in .left {
  width: 35%;
  float: left;
  margin-right: 5%;
}

.bfdl_1_2box_in .right {
  width: 60%;
  float: right;
}

.bfdl_1_2box_in .left img {
  height: 90px !important;
}

.bfdl_tit02 {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 20px;
  line-height: 1.2;
}

.bfdl_btn a {
  display: inline-block;
  background-color: #ffaf2c;
  border: 2px solid #ffaf2c;
  color: #ffffff !important;
  font-size: 14px;
  font-weight: bold;
  padding: 4px 5% 2px !important;
}

.bfdl_btn a:hover {
  text-decoration: none;
  background-color: #ffffff;
  color: #ffaf2c !important;
  text-decoration: none !important;
}

@media only screen and (max-width: 690px) {
  .bfdl_1_2box_in {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 100%;
    flex: 0 1 100%;
    margin-bottom: 10px;
  }
}

/*---------------------------
目次
----------------------------*/
.toc_area {
  width: 100%;
  background-color: #f5f5f5;
  padding: 25px;
  box-sizing: border-box;
}
.toc_title {
  position: relative;
  font-size: 20px;
  line-height: 1;
}

.toc_list {
  position: relative;
  margin: 15px 0 0 0;
  padding-left: 2em;
}
.toc_list::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 8px;
  width: 1px;
  height: 100%;
  background-color: #ccc;
}

.toc_list li {
  position: relative;
  list-style-type: none;
  margin-bottom: 10px !important;
  padding-left: 1em;
}
.toc_list li::before {
  content: '';
  display: block;
  width: 5px;
  height: 5px;
  border-radius: 50px;
  background: #322c90;
  position: absolute;
  top: 0.65em;
  left: 0;
}
.toc_list li::marker {
  color: #322c90;
}

.toc_list li a {
  color: #333333 !important;
  text-decoration: none !important;
}

.toc_list li a + .toc_list {
  margin-top: 8px;
  margin-left: 20px;
}
@media only screen and (max-width: 690px) {
  .toc_area {
    padding: 15px;
  }
  .toc_title {
    font-size: 16px;
  }
}

.current_description {
  padding: 16px;
  border: 4px solid #f5f5f5;
  margin-bottom: 30px;
}

/* table of contents **********************************/
.media_table {
  padding: 10px 20px 20px;
  background-color: #fafafa;
  border: 3px solid #eeeeee;
}

.media_table ul {
  margin-top: 10px;
  margin-left: 20px;
}

.media_table li {
  list-style-type: decimal;
  color: #999999;
}

.media_table > ul > li {
  list-style-type: decimal;
  font-size: 110%;
}

article#main .media_table a {
  color: #333333;
  text-decoration: none;
}

article#main .media_table a:hover {
  text-decoration: underline;
}

@media only screen and (max-width: 690px) {
  .media_table {
    padding: 10px 15px 20px;
  }

  .media_table ul {
    margin-left: 15px;
  }
}

/* search ***/
.blog_search {
  width: 100%;
  border-radius: 50px;
  margin-bottom: 30px;
  overflow: hidden;
}

.blog_search form {
  display: flex;
}

.blog_search input {
  display: block;
  padding: 0 1em;
  border-radius: 0;
  height: 48px;
  border: none;
  background: #fff;
}

.blog_search input.search_txt {
  line-height: 36px;
  width: calc(100% - 40px);
}

.blog_search input.search_txt:focus {
  outline: none;
}

.blog_search input.submit_btn {
  width: 40px;
  cursor: pointer;
  padding: 0;
  color: transparent;
  text-indent: -9999px;
  /* background: url(/dcms_media/image/icon_search.png) no-repeat #f5f5f5 right 15px center/25px; */
}

.side {
  margin-bottom: 2.5rem;
}
.side .c-bttn {
  display: none;
  // padding: 1.3em;
  // display: flex;
}

.side .reco {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 20px;
}
.side .reco li {
  margin-bottom: 0px !important;
}
.side .reco .splide__slide {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-bottom: 15px;
}
.side .reco .splide__slide:hover {
  opacity: 0.7;
}
.side .reco .taglist {
}
.side .reco .taglist a {
  font-size: 10px;
  display: inline-block;
}
.side .reco .btxt,
.side .reco .catelist {
  display: none;
}
@media only screen and (max-width: 980px) {
  .side .reco {
    flex-direction: row;
  }
  .side .reco li {
    width: calc(50% - 10px);
    /* margin-bottom: 0 !important; */
  }
}
@media only screen and (max-width: 690px) {
  .side .reco li {
    width: 100%;
  }
}

.side .title {
  font-size: 16px;
  line-height: 1.4;
  text-align: left;
  border-bottom: 1px solid #ddd;
  padding-bottom: 0.7em;
  margin-bottom: 0.8em;
}

.side .blog-image {
  width: 85px;
  height: 56px;
}
.side .blog-image img {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
}

.side .blog-data {
  flex: 1;
}
.side .blog-data .d-flex {
  /* margin-bottom: 3px; */
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: space-between; */
  /* border-bottom: 1px solid #ddd; */
  /* padding-bottom: 3px; */
}
.side .blog-data time {
  font-size: 10px;
  display: none;
}
.side .htitle {
  font-size: 16px;
  margin-bottom: 0;
}
.side .htitle a {
  display: inline-block;
  font-size: 13px;
  text-decoration: none;
  font-weight: 400;
  line-height: 1.4;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  letter-spacing: 0.08em;
}
.side li {
  list-style-type: none;
}
.side .archive,
.side .cate,
.side .tag {
  margin-bottom: 15px;
}
.side .cate,
.side .tag {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
}
.side .archive a {
  text-decoration: underline !important;
  font-size: 13px;
}
.side .archive a:hover {
  opacity: 0.7;
}
.catelist,
.catelist a,
.taglist,
.taglist a,
.side .tag a {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  font-size: 11px;
  font-weight: 700;
  text-decoration: none;
  letter-spacing: 0.1em;
}
.taglist a {
  color: #574fc2;
  font-size: 15px;
}

.side .tag a::before {
  content: '#';
  display: inline-block;
  margin-right: 3px;
}
.list.cate {
  display: inline-flex;
  flex-wrap: wrap;
  gap: 6px;
}
.catelist a,
.list.cate a,
.side .cate a {
  display: inline-flex;
  padding: 5px 4px;
  border-radius: 50px;
  border: 2px solid #a0a0fb;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  font-weight: 700;
  text-decoration: none;
  line-height: 1.2;
  background: #fff;
  letter-spacing: initial;
  transition: 0.3s;
}

.list.cate a,
.side .cate a {
  color: #333;
  background-color: #a0a0fb;
}
.catelist a:hover,
.side .cate a:hover {
  text-decoration: none;
  color: #a0a0fb;
  background-color: #fff;
}

.archivelist {
  display: inline-block;
  margin-bottom: 20px;
}
/*.blog-slider*/
.blog-slider {
  @include sp {
    width: 90%;
    margin: 0 auto 60px;
  }
  position: relative;
  width: 100%;
  margin: 0 auto 80px;
  .splide {
    margin: auto;
    padding-bottom: 30px;
  }
  .splide__track {
    @include sp {
      transform: translateX(0%);
      padding-bottom: 20px;
    }
  }
  .splide__slide {
    @include sp {
      width: 100% !important;
      transform: scale(1);
    }
    width: 33.33% !important;
    border-radius: 10px;
    /*margin: 0 10px;*/
    overflow: hidden;
    // box-shadow: 0 10px 40px -10px rgb(0 0 0 / 10%);
    transform: scale(0.9);
    transform-origin: center;
    transition: 0.6s;
    background: #fff;
  }
  .splide__slide:hover {
    opacity: 0.7 !important;
  }
  .blog-data {
    padding: 25px;
    background-color: #fff;
    .d-flex {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 5px;
    }
    time {
      display: none;
    }
    .htitle a {
      @include pc {
        font-size: 22px;
      }
      @include tb {
        font-size: 20px;
      }
      text-decoration: none;
      display: inline-block;
      font-size: 26px;
      line-height: 1.2;
      margin-bottom: 0.5em;
    }
    .btxt {
      /* margin-bottom: 10px; */
      display: none;
    }
  }
  .blog-image {
    @include tb {
      height: 150px;
    }
    @include sp {
      height: 180px;
    }
    position: relative;
    width: 100%;
    height: 300px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .splide__slide.is-next {
    transform: translateY(0%) scale(1);
    /*opacity: 1;*/
  }
  .splide__pagination__page.is-active {
    background: #000000;
  }
  .splide__navi {
    @include sp {
      position: static;
      margin: 0 auto;
      width: 100%;
      justify-content: center;
      transform: translateX(0);
    }
    position: absolute;
    left: 50%;
    bottom: 0;
    display: flex;
    align-items: center;
    transform: translateX(-50%);
  }
  .splide__arrows {
    position: static;
    transform: translateY(0%);
    display: flex;
    justify-content: center;
    width: 160px;
    .splide__arrow {
      background: transparent;
    }
  }
  .splide__pagination {
    display: flex;
    gap: 8px;
    position: static;
  }
  .splide__pagination li {
    display: flex;
  }
  .splide__pagination__page {
    width: 15px;
    height: 3px;
    margin: 0;
    border-radius: 5px;
    &.is-active {
      transform: scale(1);
    }
  }
}

.pager {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

// .pager a:not(.prev, .next) {
//   background: #fff;
//   line-height: 1.2;
//   color: #333;
//   border-radius: 5px;
//   width: 30px;
//   height: 30px;
//   display: inline-flex;
//   align-items: center;
//   justify-content: center;
//   transition: 0.6s;
// }

.pager {
  a {
    &:not(.prev):not(.next) {
      background: #fff;
      line-height: 1.2;
      color: #333;
      border-radius: 5px;
      width: 30px;
      height: 30px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      transition: 0.6s;
    }
  }
}

// .pager a:not(.prev, .next):hover {
//   text-decoration: none;
//   opacity: 0.7;
// }

.pager {
  a {
    &:not(.prev):not(.next) {
      &:hover {
        text-decoration: none;
        opacity: 0.7;
      }
    }
  }
}

.pager a.prev,
.pager a.next {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pager a.prev::before,
.pager a.next::before {
  content: '';
  position: relative;
  display: inline-block;
  border-style: solid;
  border-color: #333;
  width: 12px;
  height: 12px;
  transform: rotate(45deg);
}
.pager a.prev::before {
  border-width: 0 0 2px 2px;
  left: 8px;
}
.pager a.next::before {
  border-width: 2px 2px 0 0;
  left: -8px;
}
.pager span {
  background: #333;
  line-height: 1.2;
  color: #fff;
  border-radius: 5px;
  width: 30px;
  height: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: 0.6s;
}
.newold {
  margin-bottom: 10px;
}
