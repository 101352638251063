@import '../foundation/variable';
@import '../foundation/mixin';

.form_wrap {
}

input,
textarea,
select {
  border: 0;
  padding: 5px 7px;
  color: $black;
  border: solid 1px #ccc;
  border-radius: 3px;
}
input {
  @include sp {
    width: 100%;
  }
}

input[type='file'] {
  border: none;
  padding-left: 0;
  //   font-size: 16px;
}

select {
  color: $black;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  line-height: 1.8;
  padding: 6px 64px 6px 24px;
}

// #table_address {
//   select {
//     padding: 3px 24px;
//     background: $lightgray;
//     color: $black;
//     font-size: 16px;
//   }
// }

input:focus,
textarea:focus,
select:focus {
  border: solid 1px $main;
  outline: none;
}

/*form pageContact,Enquete common*/
/*-----------------------------------------------------------------------*/
#submit_button {
  text-align: center;
  margin: auto;
  input {
    @include sp {
      width: 180px;
      margin: 20px auto 0;
    }
    width: 230px;
    height: 66px;
    position: relative;
    padding: 1em;
    color: $black;
    font-size: 18px;
    font-weight: 700;
    text-decoration: none;
    line-height: 1.2;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-style: solid;
    border-width: 3px;
    border-color: $black;
    background: $white;
    border-radius: 50px;
    letter-spacing: 0.1em;
    transition: 0.6s;
    cursor: pointer;
    &:hover {
      background-color: $black;
      color: $white;
    }
  }
}

.form_contact {
  @include tb {
    padding: 40px 30px;
  }
  @include sp {
    padding: 30px;
  }
  position: relative;
  padding: 60px 60px 40px;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    background: $white;
    z-index: -2;
  }
  dl {
    @include sp {
      padding: 10px 0;
      flex-direction: column;
      gap: 10px;
    }
    padding: 40px 0;
    width: 100%;
    display: flex;
    border-top: 1px solid #ddd;
    gap: 30px;
    &:first-child {
      padding-top: 0;
      border-top: none;
    }
    &:last-of-type {
      dt,
      dd {
        @include sp {
          margin-bottom: 0;
        }
        padding-bottom: 0;
      }
    }
  }
  .dcms_error_area {
    &:first-child {
      padding-top: 20px;
    }
  }
  dt {
    @include tb {
      width: 27%;
    }
    @include sp {
      width: 100%;
    }
    position: relative;
    padding: 0;
    width: 25%;
    display: inline-flex;
    justify-content: flex-end;
    flex-direction: row-reverse;
    align-items: center;
    gap: 10px;
    span {
      @include tb {
        display: block;
        line-height: 1;
        margin-top: 5px;
      }
      color: $white;
      background: #fe5072;
      line-height: 1;
      font-size: 12px;
      padding: 7px 12px;
      border-radius: 3px;
      white-space: nowrap;
    }
  }
  dd {
    @include tb {
    }
    @include sp {
      width: 100%;
      flex: initial;
    }
    position: relative;
    flex: 1;
    display: flex;
    gap: 10px;
  }
  .otherselect {
    @include tb {
      left: 27%;
      width: 73%;
    }
    @include sp {
      left: 0;
      width: 100%;
    }
    position: relative;
    top: -20px;
    left: 25%;
    width: 75%;
    border-top: 0;
    dt {
      @include tb {
        padding: 0 10px;
      }
      @include sp {
        padding: 0;
        border: none;
      }
    }
    dt,
    dd {
      @include tb {
        display: block;
        width: 100%;
      }
      padding-top: 0;
    }
  }
}
.form_contact-txt {
  text-align: center;
  margin-bottom: 40px;
}

#privacy_txt {
  @include tb {
    margin-left: calc(27% + 30px);
  }
  @include sp {
    margin-left: 0;
    transform: translateY(0%);
  }
  position: relative;
  top: 0;
  margin-left: calc(25% + 30px);
  font-size: 13px;
  transform: translateY(-100%);
  a {
    color: #fe5072;
    &::after {
      display: none;
    }
  }
}

/*form pageEnquete*/
#basic_information {
  border-bottom: 1px solid #adadad;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 1.4;
  margin-bottom: 25px;
  padding-bottom: 10px;
}

#form_enquetet_main {
  overflow: auto;
  margin-top: 30px;
}

#enquete_intro {
  border-bottom: 1px solid #adadad;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 1.4;
  margin-bottom: 25px;
  padding-bottom: 10px;
}

#form_enquetet_main dl {
  width: 100%;
  border-top: 1px dotted #ddd;
  line-height: 220%;
  float: left;
}

#form_enquetet_main dl:nth-child(2) {
  border-top: none;
}

#form_enquetet_main dl.privacy {
  margin-top: 30px;
  border-bottom: none;
}

#form_enquetet_main dt {
  float: left;
  padding: 30px 10px;
  width: 30%;
}

#form_enquetet_main dt span {
  color: #fe5072;
}

#form_enquetet_main dd {
  float: left;
  padding: 30px 10px;
  width: 70%;
}

/*form utility*/
.example_text {
  margin-top: 5px;
  line-height: 100%;
  font-size: 90%;
  color: #999999;
}

#family_name,
#first_name,
#family_name_yomi,
#first_name_yomi {
  width: 120px;
  margin-right: 20px;
}

#zip01,
#zip02 {
  width: 60px;
}

#female {
  margin-left: 20px;
}

#table_address td {
  padding: 3px 0 3px 0;
}

#table_address td:first-child {
  width: 100px;
}

span.break {
  padding-left: 15px;
}

.width_cs {
  width: 100%;
}

.width_cm {
  width: 100%;
}

.width_cl {
  width: 100%;
}

.width_es {
  width: 100%;
}

.width_em {
  width: 100%;
}

.width_el {
  width: 100%;
}

.height_s {
  height: 50px;
}

.height_m {
  height: 100px;
}

.height_l {
  height: 150px;
}

/*-エラー項目部分のハイライト-*/
.dcms_error_area {
  position: relative;
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: calc(100% + 20px);
    height: calc(100% - 20px);
    background: #ffd7d7;
    transform: translate(-50%, -50%);
    border-radius: 3px;
    z-index: -1;
  }
}

.error_text {
  display: none;
}

.dcms_error_area {
  .error_text {
    display: block;
    width: auto;
    margin-top: 5px;
    color: #fff;
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(100%);
    z-index: 1;
    animation: dcms_fadein_up 0.8s 1;
    &:before {
      content: '';
      width: 0px;
      height: 0px;
      position: absolute;
      text-align: left;
      top: -9px;
      left: 10px;
      border: 5px solid transparent;
      border-bottom: 5px solid $main;
      /*アニメーションをつける場合*/
      -webkit-animation: dcms_fadein_up 0.8s 1;
      -moz-animation: dcms_fadein_up 0.8s 1;
      -ms-animation: dcms_fadein_up 0.8s 1;
      -o-animation: dcms_fadein_up 0.8s 1;
      animation: dcms_fadein_up 0.8s 1;
    }
    span {
      background: $main;
      display: inline-block;
      padding: 7px;
    }
  }
}
@keyframes dcms_fadein_up {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@media only screen and (max-width: 979px) {
  .width_cs {
    width: 100%;
  }

  .width_cm {
    width: 100%;
  }

  .email_input {
    width: 100%;
  }
}
input.family_name,
input.first_name {
  /* width: calc(50% - 1.4em - 10px); */
  width: calc(50% - 1em - 18px);
}

input.family_name,
input.family_name_yomi {
}

input.family_name_yomi,
input.first_name_yomi {
  flex: 1;
}

@media only screen and (max-width: 690px) {
  input.family_name,
  input.first_name,
  input.family_name_yomi,
  input.first_name_yomi {
    width: 100%;
    display: block;
  }

  input.family_name,
  input.family_name_yomi {
    margin-right: 0;
    margin-bottom: 5px;
  }
}

.form_confirm-txt {
  padding: 23px;
  font-size: 90%;
  border: 1px solid $main;
}
.thanks-txt {
  padding: 23px;
  border: 1px solid $main;
}
