@import "../../foundation/variable";
@import "../../foundation/mixin";

@for $i from 0 through 200 {
  .u-p-#{$i} {
    padding: 1px * $i + $imp;
  }
  .u-pt-#{$i} {
    padding-top: 1px * $i + $imp;
  }
  .u-pr-#{$i} {
    padding-right: 1px * $i + $imp;
  }
  .u-pb-#{$i} {
    padding-bottom: 1px * $i + $imp;
  }
  .u-pl-#{$i} {
    padding-left: 1px * $i + $imp;
  }
  .u-py-#{$i} {
    padding-top: 1px * $i + $imp;
    padding-bottom: 1px * $i + $imp;
  }
  .u-px-#{$i} {
    padding-right: 1px * $i + $imp;
    padding-left: 1px * $i + $imp;
  }
  @include tb {
    .u-p-tb-#{$i} {
      padding: 1px * $i + $imp;
    }
    .u-pt-tb-#{$i} {
      padding-top: 1px * $i + $imp;
    }
    .u-pr-tb-#{$i} {
      padding-right: 1px * $i + $imp;
    }
    .u-pb-tb-#{$i} {
      padding-bottom: 1px * $i + $imp;
    }
    .u-pl-tb-#{$i} {
      padding-left: 1px * $i + $imp;
    }
    .u-py-tb-#{$i} {
      padding-top: 1px * $i + $imp;
      padding-bottom: 1px * $i + $imp;
    }
    .u-px-tb-#{$i} {
      padding-right: 1px * $i + $imp;
      padding-left: 1px * $i + $imp;
    }
  }
  @include sp {
    .u-p-sp-#{$i} {
      padding: 1px * $i + $imp;
    }
    .u-pt-sp-#{$i} {
      padding-top: 1px * $i + $imp;
    }
    .u-pr-sp-#{$i} {
      padding-right: 1px * $i + $imp;
    }
    .u-pb-sp-#{$i} {
      padding-bottom: 1px * $i + $imp;
    }
    .u-pl-sp-#{$i} {
      padding-left: 1px * $i + $imp;
    }
    .u-py-sp-#{$i} {
      padding-top: 1px * $i + $imp;
      padding-bottom: 1px * $i + $imp;
    }
    .u-px-sp-#{$i} {
      padding-right: 1px * $i + $imp;
      padding-left: 1px * $i + $imp;
    }
  }
}
