@import '../../foundation/variable';
@import '../../foundation/mixin';
.inner {
  position: relative;
  max-width: 1380px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}
.txt_l {
  text-align: left;
}
.txt_llc {
  @include sp {
    text-align: center;
  }
  text-align: left;
}
.txt_lcc {
  @include tb {
    text-align: center;
  }
}
.txt_r {
  text-align: right;
}
.txt_c {
  text-align: center;
}
.txt_rrc {
  @include sp {
    text-align: center;
  }
  text-align: right;
}
.txt_rcc {
  @include tb {
    text-align: center;
  }
  text-align: right;
}
.txt_cll {
  @include tb {
    text-align: left;
  }
  text-align: center;
}
.txt_ccl {
  @include sp {
    text-align: left;
  }
  text-align: center;
}
.txt_rlr {
  @include tb {
    text-align: left;
  }
  @include sp {
    text-align: right;
  }
  text-align: right;
}
.txt_lrl {
  @include tb {
    text-align: right;
  }
  @include sp {
    text-align: left;
  }
  text-align: left;
}
.text-justify {
  text-align: justify;
}
// on off
.sp_on {
  @include tb {
    display: none;
  }
  @include sp {
    display: block;
  }
  display: none;
}

.tb_on {
  @include tb {
    display: block;
  }
  @include sp {
    display: none;
  }
  display: none;
}
.sp_on.tb_on {
  @include tb {
    display: block;
  }
  @include sp {
    display: block;
  }
  display: none;
}
.pc_on {
  @include lg {
  }
  @include tb {
    display: none;
  }
  @include sp {
    display: none;
  }
}
.pc_on.tb_on {
  @include sp {
    display: none;
  }
  display: block;
}
.lg_on {
  @include pc {
    display: none;
  }
}
.xlg_on {
  @include lg {
    display: none;
  }
}
.xxlg_on {
  @include xlg {
    display: none;
  }
}
.xxxlg_on {
  @include xxlg {
    display: none;
  }
}
.outlink {
  display: inline-block;
  &::after {
    content: '\F1C5';
    display: inline-flex;
    align-self: end;
    font-family: bootstrap-icons;
    font-weight: 400;
    font-size: 0.8rem;
    margin-left: 0.5em;
  }
}
.nowrap {
  white-space: nowrap;
}
.letter-spacing {
  &-ini {
    letter-spacing: initial;
  }
}
.img100 {
  img {
    width: 100% $imp;
  }
}
// max-width
@for $i from 100 through 2000 {
  .mw {
    &#{$i} {
      max-width: $i + px;
      width: 100%;
    }
  }
}
[class*='mw'].inner {
  width: 90%;
}

.border {
  border: 1px solid #ddd;
}
.border8 {
  border: 8px solid #ddd;
}

.tbl {
  width: 100%;
  table-layout: auto;
  tr {
    &:last-child {
      th {
        @include sp {
          border-bottom: 1px solid #ddd;
        }
        border: none;
      }
      td {
        border: none;
      }
    }
  }
  th {
    @include tb {
      width: 200px;
    }
    @include sp {
      width: 100%;
      padding: 0.6em;
      display: block;
    }
    width: 270px;
    text-align: center;
    font-weight: 500;
    padding: 1em;
    border-bottom: 1px solid #ddd;
  }
  td {
    @include sp {
      width: 100%;
      padding: 0.6em;
      display: block;
    }
    padding: 1em;
    border-bottom: 1px solid #ddd;
  }
}
.tbl-02 {
  width: 100%;
  border-collapse: separate;
  border-spacing: 15px 0;
  table-layout: auto;
  tr {
    &:last-child {
      td {
        border: none;
      }
    }
  }
  th {
    @include tb {
      width: 200px;
    }
    @include sp {
      width: 100%;
      padding: 0.6em;
      display: block;
    }
    width: 270px;
    font-weight: 500;
    padding: 1em 1em 1em 0;
    border-bottom: 1px solid #ddd;
    vertical-align: top;
    &.last-child {
      border: none;
    }
  }
  td {
    @include sp {
      width: 100%;
      padding: 0.6em;
      display: block;
    }
    width: calc(100% - 270px);
    padding: 1em;
    border-bottom: 1px solid #ddd;
  }
}
.tbl-03 {
  width: 100%;
  table-layout: auto;
  tr {
    &:first-child {
      th {
        border-top: 1px solid #ddd;
      }
      td {
        @include sp {
          border-top: none;
        }
        border-top: 1px solid #ddd;
      }
    }
  }
  th {
    @include tb {
      width: 200px;
    }
    @include sp {
      width: 100%;
      // padding: 0.6em;
      display: block;
    }
    width: 270px;
    text-align: center;
    font-weight: 500;
    padding: 1em;
    border-bottom: 1px solid #ddd;
  }
  td {
    @include sp {
      width: 100%;
      // padding: 0.6em;
      display: block;
    }
    padding: 1em;
    border-bottom: 1px solid #ddd;
  }
}

// width
@for $i from 10 through 100 {
  .w#{$i} {
    width: 1px * $i;
  }
  @include tb {
    .w#{$i}-tb {
      width: 1px * $i + $imp;
    }
  }
  @include sp {
    .w#{$i}-sp {
      width: 1px * $i + $imp;
    }
  }
}

.tel {
  a {
    @include tb {
      color: $main;
      pointer-events: auto;
    }
    color: $black;
    text-decoration: none;
    pointer-events: none;
  }
}
