@import '../foundation/variable';
@import '../foundation/mixin';

.c-service {
  svg {
    @include sp {
      display: none;
    }
    a {
      .st4 {
        transition: 0.6s;
        &:hover {
          fill: #a0a0fb;
        }
      }
      .st6 {
        transition: 0.6s;
        &:hover {
          fill: $main;
        }
      }
    }
  }
}
.c-feature {
  position: relative;
  padding: 80px 0;
  background: $navy;
  overflow: hidden;
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: -10vh;
    left: -20vw;
    width: 1661px;
    height: 2093px;
    opacity: 0.1;
    background: url(/dcms_media/other/index_bg.svg) no-repeat 0 0 / contain;
    z-index: 0;
  }
}
.c-case {
  @include pc {
    padding: 100px 0 0;
    height: auto;
  }
  @include sp {
    padding: 60px 0;
  }
  position: relative;
  padding: 220px 0 0;
  height: 850px;
  &::before {
    @include pc {
      width: calc(50vw + 100px);
    }
    content: '';
    display: block;
    width: 50vw;
    height: 60%;
    background: $lightgray;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
  &::after {
    @include xlg {
      width: calc(50vw + 100px);
    }
    @include pc {
      width: calc(40vw + 50px);
    }
    content: '';
    display: block;
    width: calc(50vw + 200px);
    height: calc(100% - 160px);
    background: $navy;
    position: absolute;
    top: 150px;
    right: 0;
    z-index: -1;
  }
  .inner {
    @include sp {
      margin-bottom: 30px;
    }
  }
  .titl-area {
    .c-titl {
      @include sp {
        text-align: center;
        margin-bottom: 0;
      }
      margin-bottom: 10px;
    }
  }
  &-slider {
    @include pc {
      width: 100%;
      position: static;
      transform: translate(0, 0);
    }
    position: relative;
    top: 0;
    left: calc(50vw - 690px);
    transform: translate(360px, 30px);
    .splide__list {
      @include pc {
        padding: 50px 5% !important;
      }
      @include sp {
        padding: 0 !important;
      }
      padding: 50px 5% !important;
    }
    .splide__slide {
      @media only screen and (max-width: 1780px) {
        width: calc(23% - 30px) !important;
      }
      @include xlg {
        width: calc(25% - 30px) !important;
      }
      @include pc {
        width: calc(37% - 30px) !important;
        margin-left: 15px !important;
        margin-right: 15px !important;
      }
      @include tb {
        width: calc(55.5% - 30px) !important;
      }
      @include sp {
        width: calc(100% - 30px) !important;
      }
      list-style-type: none;
      width: calc(18% - 30px) !important;
      border-radius: 8px;
      background: $white;
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
      transition: 0.3s;
      margin-right: 30px !important;
      transition: 0.3s;
      overflow: hidden;
      &:hover {
        transform: translateY(-3px);
        box-shadow: 0 20px 60px rgba(0, 0, 0, 0.3);
      }
    }
    .catelist {
      display: none;
    }
    .htitle {
      @include xlg {
        font-size: 16px;
        line-height: 1.4;
      }
      font-size: 18px;
      line-height: 1.6;
      font-weight: 700;
      a {
        display: inline-block;
        text-decoration: none;
        color: $black;
      }
    }
    .blog-image {
      @include xlg {
        height: 130px;
      }
      height: 160px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .blog-data {
      padding: 30px 20px;
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
    .c-linker {
      position: relative;
      color: $black;
      text-align: right;
      text-decoration: underline;
    }
    .splide__arrows {
      @include pc {
        display: block;
      }
      display: none;
    }
    .splide__pagination {
      // display: none;
    }
  }
}
.c-security {
  @include pc {
    gap: 30px;
  }
  display: flex;
  flex-wrap: wrap;
  gap: 60px;
  .col {
    @include pc {
      width: calc(33.33% - 20px);
      padding: 40px 20px 30px;
      gap: 10px;
    }
    @include tb {
      width: calc(50% - 15px);
    }
    @include sp {
      width: 100%;
    }
    padding: 50px 30px 40px;
    width: calc(33.33% - 40px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    border-radius: 8px;
    background: $white;
    overflow: hidden;
  }
  .icon {
    @include pc {
      height: 110px;
    }
    @include sp {
      height: 90px;
    }
    display: flex;
    align-items: flex-end;
    justify-content: center;
    height: 165px;
    img {
      // @include pc {
      //   max-height: 110px;
      // }
      // @include sp {
      //   max-height: 90px;
      // }
      max-height: 100%;
    }
  }
  .c-titl {
    @include xlg {
      font-size: 20px;
    }
    font-size: 24px;
    font-weight: 500;
  }
  .txt {
    align-self: flex-start;
  }
}

.c-dwonload {
  @include pc {
    gap: 30px;
  }
  display: flex;
  flex-wrap: wrap;
  gap: 60px;
  .inn {
    @include pc {
      padding: 20px;
      gap: 10px;
    }
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  .col {
    @include pc {
      width: calc(33.33% - 20px);
    }
    @include tb {
      width: calc(50% - 15px);
    }
    @include sp {
      width: 100%;
    }
    width: calc(33.33% - 40px);
    border-radius: 8px;
    background: $white;
    overflow: hidden;
  }
  .document {
    aspect-ratio: 16/9;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .c-titl {
    @include pc {
      font-size: 20px;
    }
    font-size: 24px;
    font-weight: 500;
    align-self: flex-start;
  }
  .txt {
    align-self: flex-start;
  }
}

// form
.form-head {
  @include sp {
    padding: 30px;
  }
  position: relative;
  padding: 60px;
  max-width: 800px;
  width: 90%;
  border: 8px solid $navy;
  border-radius: 30px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  &::before,
  &::after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: 0;
    display: block;
    width: 186px;
    height: 52px;
    transform: translate(-50%, 100%);
  }
  &::before {
    background: $lightgray;
  }
  &::after {
    background: url(/dcms_media/image/img_baloon-slash-navy.png) no-repeat center / contain;
  }
  .c-titl {
    @include sp {
      font-size: 22px;
      margin-bottom: 10px;
    }
  }
  .u-flex {
    @include sp {
      gap: 10px;
    }
    gap: 20px;
    align-items: center;
    justify-content: center;
  }
  .tel {
    @include sp {
      font-size: 33px;
    }
    font-size: 43px;
    font-weight: 700;
    a {
      color: $navy;
    }
  }
  .time {
    padding: 10px 15px;
    font-size: 13px;
    line-height: 1;
    border: 1px solid $black;
  }
}
.c-feature-list {
  @include xlg {
    gap: 40px;
  }
  @include sp {
    gap: 30px;
  }
  display: flex;
  flex-wrap: wrap;
  gap: 2.8vw;
  .col {
    @include xlg {
      width: calc((100% / 2) - 20px);
      display: grid;
      grid-template-columns: auto;
      grid-template-rows: auto;
      grid-column-gap: 30px;
    }
    @include pc {
      display: flex;
      align-items: center;
    }
    @include sp {
      width: 100%;
    }
    padding: 40px 20px;
    width: calc((100% / 4) - 2.1vw);
    display: flex;
    flex-direction: column;
    gap: 20px;
    border-radius: 8px;
    background: $white;
  }
  &--img {
    @include xlg {
      max-width: 10vw;
      grid-column: 1/2;
      grid-row: 1/5;
    }
    @include pc {
      max-width: 100%;
    }
    img {
      @include pc {
        height: 100px;
      }
    }
  }
  .letter-spacing {
    letter-spacing: 0.05em;
  }
  &--titlarea {
    @include xlg {
      grid-column: 2/3;
      grid-row: 1/3;
      justify-content: flex-start;
      margin-bottom: 0;
    }
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  .c-titl {
    @media only screen and (max-width: 1600px) {
      font-size: calc(13px + 0.45vw);
    }
    font-size: 24px;
    font-weight: 500;
  }
  &--txt {
    @include xlg {
      grid-column: 2/3;
      grid-row: 2/4;
    }
    text-align: left;
    line-height: 1.8;
  }
}
.c-login {
  @include pc {
    gap: 30px;
  }
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  .col {
    @include lg {
      padding: 30px;
    }
    @include sp {
      width: 100%;
    }
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;
    padding: 40px;
    width: calc(50% - 20px);
    border-radius: 8px;
    background: $white;
  }
  .c-titl {
    @include pc {
      margin-left: 50px;
    }
    @include tb {
      font-size: 18px;
    }
    font-size: 24px;
    font-weight: 500;
    margin-left: 60px;
    .-sub {
      font-size: 60%;
    }
  }
  &-icon {
    @include pc {
      top: 30px;
      left: 30px;
      font-size: 32px;
    }
    @include tb {
      top: 25px;
    }
    position: absolute;
    top: 40px;
    left: 40px;
    font-size: 40px;
    line-height: 1;
    .bi {
      margin: 0;
    }
  }
  &-txt {
    @include pc {
      width: calc(100% - 50px);
      font-size: 11px;
      margin-left: 50px;
    }
    @include tb {
      width: 100%;
      margin-left: 0;
    }
    width: calc(100% - 60px);
    text-align: left;
    margin-left: 60px;
  }
}
.c-partners {
  @include sp {
    padding: 0;
  }
  position: relative;
  padding: 210px 0 100px;
  &::after {
    @include sp {
      display: none;
    }
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 45vw;
    height: 50%;
    background: $navy;
    z-index: -1;
  }
  .inner {
    position: relative;
    display: flex;
  }
  .titlarea {
    @include pc {
      width: 60%;
      padding: 40px 20px;
    }
    @include sp {
      position: static;
      width: 100%;
      padding: 30px 20px;
    }
    position: absolute;
    top: -90px;
    left: 0;
    max-width: 690px;
    width: 50%;
    padding: 100px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    border-radius: 8px;
    background: $white;
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
  }
  .c-titl {
    @include tb {
      font-size: 24px;
    }
    font-size: 28px;
    font-weight: 500;
  }
  .u-list-decimal {
    @include tb {
      font-size: 24px;
      margin-top: 0;
    }
    font-size: 28px;
    margin-top: 40px;
    padding-inline-start: 0;
    margin-left: 0;
    li {
      list-style-position: inside;
    }
  }
  .u-list-check {
    @include sp {
      font-size: 15px;
    }
    margin-top: 10px;
    font-size: 18px;
  }
  &-img {
    @include sp {
      width: 120vw;
      margin-top: -60px;
      margin-left: -20vw;
    }
    position: relative;
    width: 65vw;
    margin-left: auto;
    z-index: -1;
    img {
      width: 100%;
      margin-right: 0;
    }
    &::after {
      @include sp {
        display: none;
      }
      content: '';
      position: absolute;
      top: -210px;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      background: $lightgray;
      z-index: -1;
    }
  }
}
.c-private-service {
  @include pc {
    margin-top: 80px;
  }
  @include tb {
    margin-top: 60px;
  }
  margin-top: 120px;
  .c-titl {
    text-align: center;
    margin-bottom: 20px;
  }
  .innbox {
    @include tb {
      padding: 20px;
    }
    position: relative;
    padding: 40px;
    border: 8px solid $main;
    border-radius: 30px;
    &::before,
    &::after {
      content: '';
      position: absolute;
      left: 50%;
      bottom: 0;
      display: block;
      width: 156px;
      height: 60px;
      transform: translate(-50%, 100%);
    }
    &::before {
      background: $white;
    }
    &::after {
      background: url(/dcms_media/image/img_baloon-slash.png) no-repeat center / contain;
    }
  }
  .u-flex {
    @include pc {
      flex-direction: column;
    }
    gap: 20px;
  }
  .u-list-check {
    gap: 8px;
    flex: 1;
  }
}
.c-comparison {
  .u-flex {
    @include tb {
      gap: 60px;
    }
    gap: 80px;
    .col {
      @include tb {
        width: 100%;
      }
      position: relative;
      width: calc(50% - 40px);
    }
    .innbox {
      @include tb {
        padding-left: 30px;
      }
      padding-left: 50px;
    }
  }
}

.c-privacy-mark {
  display: flex;
  align-items: center;
  gap: 30px;
  .privacy-mark {
    width: 77px;
  }
  .txt {
    flex: 1;
  }
}
.c-certification {
  display: flex;
  align-items: center;
  gap: 30px;
  .privacy-mark {
    width: 77px;
  }
}

.bn-form {
  @include sp {
    gap: 30px;
    flex-direction: column;
  }
  display: flex;
  gap: 60px;
  .bn-col {
    @include sp {
      width: 100%;
    }
    width: calc(50% - 30px);
  }
}

#insertbox {
  a {
    transition: 0.3s;
    &:hover {
      opacity: 0.7;
    }
  }
}
