@import '../foundation/variable';
@import '../foundation/mixin';
.column{
    .pagetitle{
        // padding-top: 0 !important;
        // margin-top: 0 !important;
        // margin-bottom: 40px;
        &__c-title{
            text-align: left;
        }
    }
    .pagetitle__inner {
        // max-width: 100%;
        // width: 100%;
    }
    .aclink{
        @include sp{
            gap: 8px;
        }
        justify-content: flex-start;
        li{
            a{
                @include sp{
                    font-size: 15px;
                }
                font-weight: 500;
            }
        }
    }
    #dcms_layoutPageBlock{
        p{
            @include sp{
                line-height: 1.6;
                font-size: 14px;
            }
            color: #707070;
            line-height: 1.7;
            font-size: 14.5px;
        }
    }
}
.column-wrap{
    @include xxlg{
        gap: 40px;
    }
    @include tb{
        width: 90%;
        flex-wrap: wrap;
        margin: auto;
        order: 0;
        gap: 0 40px;
    }
    @include sp{
        gap: 0;
    }
    max-width: 1380px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-wrap: wrap;
    gap: 80px;
    #dcms_layoutPageBlockPasteArea{
        @include tb{
            flex: initial;
            width: 100%;
        }
        flex: 1;
        order: 1;
    }
    .column-form{
        @include xxlg{
            width: 260px;
        }
        @include tb{
            width: 260px;
            order: 2;
        }
        @include sp{
            width: 100%;
            margin: auto;
        }
        position: sticky;
        padding: 10px;
        top: 140px;
        width: 300px;
        height: 900px;
        order: 2;
        // background: $white;
        display: none;
        overflow: hidden;
    }
    .column-subnavi{
        @include xxlg{
            width: 260px;
        }
        @include tb{
            width: auto;
            height: auto;
            flex: 1;
            order: 1;
        }
        @include sp{
            width: 100%;
            height: auto;
            flex: initial;
        }
        width: 300px;
        order: 0;
        position: sticky;
        top: 140px;
        width: 300px;
        height: 900px;
        &_ttl a, &_list li a{
            @include tb{
                padding: 10px;
            }
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: $white;
            line-height: 1;
            padding: 1em 15px;
            background: $navy;
            text-decoration: none;
            transition: .6s;
            &::after{
                @include tb{
                    font-size: 12px;                 
                }
                content: "\F285";
                display: inline-block;
                font-size: 13px;
                font-family: bootstrap-icons;
            }
            &:hover{
                opacity: .8;
            }
        }
        &_ttl{
            a{
                @include lg{
                    font-size: 16px;
                }
                @include tb{
                    font-size: 13px;                 
                }
                font-size: 18px;
            }
        }
        &_list{
            li{
                list-style: none;
                a{
                    @include tb{
                        font-size: 13px;                 
                    }
                    position: relative;
                    color: #999999;
                    font-size: 16px;
                    border-top: 1px solid #CCCCCC;
                    background: transparent;
                    &::before{
                        content: "";
                        position: absolute;
                        top: calc(50% - 2px);
                        left: 0;
                        display: block;
                        width: 6px;
                        height: 6px;
                        border-radius: 50px;
                        background: transparent;
                        transition: .6s;
                    }
                    &:hover{
                        opacity: .6;
                        &::before{
                            background: $currentColor;
                        }
                    }
                }
                &.current{
                    a{
                        color: $navy;
                        &::before{
                            background: $main;
                        }
                        &:hover{
                            opacity: .6;
                        }
                    }
                }
            }
        }
    }
}
.c-attendance_cover{
    @include sp{
        height: 150px;
    }
    border-radius: 8px;
    height: 300px;
    overflow: hidden;
    img{
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
}
.c-attendance_list{
    @include pc{
        grid-template-columns: 320px 1fr;
    }
    @include tb{
        grid-template-columns: 200px 1fr;
    }
    @include sp{
        grid-template-columns: auto;
    }
    position: relative;
    display: grid;
    grid-template-columns: 400px 1fr;
    // gap: 30px;
    border-radius: 8px;
    background: $white;
    transform: translateY(0);
    transition: .6s;
    overflow: hidden;
    &:hover{
        transform: translateY(-5px);
        opacity: .7;
    }
    &_img{
        @include sp{
            height: 120px;
        }
        height: 100%;
        margin-bottom: 0;
        img{
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }
    &_item{
        @include tb{
            padding: 30px;
        }
        @include sp{
            padding: 20px;
            gap: 6px;
        }
        padding: 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 10px;
    }
    .c-titl{
        font-size: calc(20px + 8 * (100vw - 320px) / 1600)
    }
    & [class*="c-bttn"]{
        @include sp{
            position: absolute;
            right: 20px;
            bottom: 15px;
        }
        text-align: left;
        a{
            position: relative;
            @include sp{
                padding: 0;
                font-size: 0px;
                color: transparent;
                min-width: auto;
                width: 13px;
                height: 13px;
                padding: 0;
                border-radius: 0;
                border-width: 2px 2px 0 0;
            }
            &:hover{
                @include sp{
                    background: transparent;
                }
            }
            &::after{
                @include sp{
                    content: "";
                    position: absolute;
                    top: 4px;
                    right: -3px;
                    width: 16px;
                    border-top: 2px solid $black;
                    transform: rotate(-45deg);
                }
            }
        }
    }
}