@import '../foundation/variable';
@import '../foundation/mixin';

.l-hdd {
  @include pc {
    height: 110px;
  }
  @include tb {
    height: 80px;
  }
  @include sp {
    height: 60px;
  }
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 140px;
  display: flex;
  align-items: center;
  background: transparent;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0);
  transition: 0.3s;
  z-index: 10;
  &.is-active {
    background: $white;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  }
  &__inner {
    @media only screen and (max-width: 1580px) {
      grid-template-columns: 160px 1fr;
    }
    @include tb {
      display: flex;
      align-items: center;
    }
    width: 100%;
    padding: 0 2%;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 240px 1fr;
    gap: 0 15px;
    margin-left: auto;
    margin-right: auto;
  }
  &__h-logo {
    @media only screen and (max-width: 1480px) {
      grid-row: 1 / 2;
    }
    margin: 0;
    grid-column: 1 / 2;
    grid-row: 1 / 3;
    align-self: center;
    margin-right: auto;
    img {
      @media only screen and (max-width: 1580px) {
        max-width: 160px;
      }
      @include tb {
        max-width: 120px;
      }
      max-width: 210px;
      margin: 0;
      transition: 0.3s;
      &:hover {
        opacity: 0.7;
      }
    }
  }
  &__sub-menu {
    display: flex;
    align-items: center;
    gap: 30px;
    padding-inline-start: 0;
    margin: 0;
    grid-column: 3/4;
    grid-row: 1/2;
    li {
      position: relative;
      list-style: none;
      & + li::before {
        content: '';
        position: absolute;
        top: 50%;
        left: -15px;
        display: inline-block;
        width: 1px;
        height: 0.8em;
        border-left: 1px solid #333;
        transform: translate(0, -50%);
      }
      a {
        display: inline-block;
        padding: 5px 0;
        font-size: 13px;
        font-weight: 700;
        line-height: 1.2;
        color: #333;
        text-decoration: none;
        transition: 0.6s;
        &:hover {
          color: $main;
        }
      }
    }
  }
  &__cv-area {
    @include sp {
      display: none;
    }
    display: flex;
    align-items: center;
    gap: 15px;
    grid-column: 5 / 6;
    grid-row: 1 / 2;
    li {
      list-style: none;
      a {
        @include tb {
          font-size: 13px;
        }
        padding: 0.6em 1.5em;
        text-decoration: none;
      }
    }
  }
  &__navi {
    @media only screen and (max-width: 1480px) {
      grid-column: 1 / 6;
    }
    @include tb {
      padding: 20px;
      position: fixed;
      top: 80px;
      right: 0;
      width: 50%;
      // width: 440px;
      height: calc(100vh - 80px);
      background: #fff;
      box-shadow: 0 10px 10px rgb(0 0 0 / 20%);
      transform: translateX(100%);
      overflow-y: scroll;
      z-index: 10;
      &.is-active {
        transform: translateX(0);
      }
    }
    @include sp {
      top: 60px;
      width: 85%;
      height: calc(100vh - 60px);
    }
    grid-column: 2 / 6;
    grid-row: 2 / 3;
    transition: 0.3s;
    > ul {
      @media only screen and (max-width: 1480px) {
        justify-content: center;
      }
      @include lg {
        gap: 0.6vw;
      }
      @include tb {
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
      }
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 1vw;
      margin: 0;
      > li {
        @include tb {
          width: 100%;
        }
        position: relative;
        list-style: none;
        &.main_current {
          a {
            &::after {
              width: 100%;
            }
          }
        }
        a {
          @include xlg {
            font-size: 12px;
            padding: 1em 0 0.6em;
          }
          @include pc {
            padding: 0.6em 0;
          }
          @include tb {
            font-size: initial;
          }
          position: relative;
          display: block;
          padding: 0.6em 0;
          color: #333;
          font-weight: 500;
          text-decoration: none;
          &::after {
            content: '';
            position: absolute;
            left: 50%;
            bottom: 0;
            display: block;
            width: 0;
            height: 2px;
            transform: translateX(-50%);
            transform-origin: center;
            background: $main;
            transition: 0.6s;
          }
          &:hover {
            &::after {
              width: 100%;
            }
          }
        }
      }
    }
    &__cv-area {
      @include tb {
        margin-top: 20px !important;
        display: flex !important;
        flex-direction: column !important;
        gap: 20px !important;
        text-align: center;
      }
      display: none !important;
      li {
        a {
          padding: 0.8em 1.5em !important;
          width: 220px;
          &::after {
            display: none !important;
          }
        }
      }
    }
  }
  &__mmn {
    cursor: pointer;
    &:hover {
      .l-hdd__mmnArea {
        @include tb {
          top: auto;
          opacity: 0;
        }
        top: 50px;
        opacity: 1;
        visibility: visible;
        // transform: translateY(0%);
        z-index: 10;
      }
      > a {
        color: $main;
      }
    }
    > a {
      @include xlg {
        padding: 1em 15px 0.6em 0 !important;
      }
      @include tb {
        // pointer-events: none;
        padding: 0.6em 5px 0.6em 0 !important;
        margin-bottom: 15px;
        width: calc(100% - 45px);
      }
      position: relative;
      display: flex !important;
      flex-direction: row-reverse;
      justify-content: flex-end;
      align-items: center;
      padding: 0.6em 12px 0.6em 0 !important;
      &::before {
        content: '';
        @include xlg {
          width: 11px;
          height: 11px;
        }
        @include pc {
        }
        @include tb {
          font-size: 14px;
          border-width: 0 2px 2px 0;
          display: none;
        }
        content: '';
        display: block;
        position: relative;
        top: -2px;
        left: 7px;
        width: 13px;
        height: 13px;
        transform: rotate(135deg) scale(1, -1);
        border-style: solid;
        border-width: 0 3px 3px 0;
        border-color: $main;
        transition: 0.6s;
      }
    }
    &.is-selected {
      > a {
        &::before {
          top: 5px;
          transform: rotate(135deg) scale(-1, 1);
        }
      }
    }
  }
  .acbtn {
    @include tb {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    display: none;
    width: 45px;
    height: 45px;
    position: absolute;
    top: 0;
    right: 0;
    background: rgba(87, 79, 194, 0.1);
    border-radius: 8px;
    transition: 0.6s;
    z-index: 10;
    &.is-selected {
      background: rgba(87, 79, 194, 0.3);
    }
    &::before {
      content: '';
      display: block;
      position: relative;
      width: 10px;
      height: 10px;
      border-style: solid;
      border-width: 0 3px 3px 0;
      border-color: $main;
      transform: rotate(45deg);
    }
  }
  &__mmnArea {
    @include tb {
      width: 100%;
      position: relative;
      top: auto;
      display: none;
      opacity: 1 !important;
      transition: auto;
      transform: translateY(0);
      visibility: visible;
    }
    padding: 15px 25px;
    position: absolute;
    top: 50px;
    left: 0;
    width: auto;
    height: auto;
    background: #ffffff;
    box-shadow: 0 0 15px rgb(0 0 0 / 20%);
    border-radius: 8px;
    opacity: 0;
    // transform: translateY(-5%);
    transition: 0.6s;
    visibility: hidden;
    z-index: -1;
    // &.is-active {
    //   @include tb {
    //     position: static;
    //     top: 50px;
    //     opacity: 1 !important;
    //     visibility: visible;
    //     transform: translateY(0%);
    //     z-index: 10;
    //   }
    // }
    ul {
      @include sp {
        gap: 13px;
      }
      display: flex;
      flex-direction: column;
      gap: 8px;
      li {
        position: relative;
        list-style: none;
        transition: 0.6s;
        &:hover {
          a {
            color: $main;
          }
          opacity: 0.7;
        }
        &::before {
          content: '';
          display: block;
          position: absolute;
          top: 50%;
          right: 0;
          width: 8px;
          height: 8px;
          transform: translate(0, -50%) rotate(45deg);
          border-style: solid;
          border-width: 2px 2px 0 0;
          border-color: currentColor;
          transition: 0.6s;
        }
        a {
          @include tb {
            font-size: initial;
            padding: 5px 0;
            display: block;
            white-space: normal;
          }
          position: relative;
          padding: 5px 20px 5px 0;
          display: inline-block;
          white-space: nowrap;
          transition: 0.6s;
          &::after {
            display: none;
          }
        }
      }
    }
  }
  &-bg {
    &.is-active {
      opacity: 1;
      visibility: visible;
    }
    @include tb {
      display: block;
    }
    display: none;
    position: absolute;
    top: 80px;
    right: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.3);
    opacity: 0;
    visibility: hidden;
    transition: 0.3s;
    z-index: -10;
  }
}
.home .l-hdd {
  background: $white;
}
#menu {
  @include tb {
    display: block;
  }
  display: none;
  cursor: pointer;
  margin: 0 10px;
  input {
    display: none;
    & + div {
      width: 24px;
      height: 18px;
      position: relative;
      div {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        transition: transform 0.5s ease;
        span {
          display: block;
          position: absolute;
          left: 0;
          right: 0;
          &:first-child {
            top: 0;
            &:before,
            &:after {
              top: 0;
            }
          }
          &:last-child {
            bottom: 0;
            &:before,
            &:after {
              bottom: 0;
            }
          }
          &:before,
          &:after {
            content: '';
            display: block;
            width: 47%;
            height: 2px;
            border-radius: 1px;
            background: $main;
            position: absolute;
            -webkit-backface-visibility: hidden;
            transition: transform 0.5s ease, border-radius 0.3s ease, background 0.4s ease;
          }
          &:before {
            left: 0;
            transform-origin: 0 50%;
            transform: translate(1px, 0) scaleX(1.1);
          }
          &:after {
            right: 0;
            transform-origin: 100% 50%;
            transform: translate(-1px, 0) scaleX(1.1);
          }
        }
      }
      svg {
        display: block;
        fill: none;
        stroke: $main;
        stroke-width: 2px;
        width: 44px;
        height: 44px;
        stroke-linecap: round;
        position: absolute;
        left: 50%;
        top: 50%;
        margin: -22px 0 0 -22px;
        stroke-dasharray: 0 84 8 84;
        stroke-dashoffset: 83;
        transform-origin: 50% 50%;
        -webkit-backface-visibility: hidden;
        transform: scale(1);
        transition: stroke-dashoffset 0.5s ease, stroke-dasharray 0.6s ease, transform 0.5s ease, stroke 0.4s ease;
        &:nth-child(3) {
          transform: rotate(180deg) scale(1);
        }
      }
    }
    &.is-active + div {
      div {
        transform: rotate(90deg);
        span {
          &:before,
          &:after {
            background: $main;
          }
          &:first-child {
            &:before {
              transform: rotate(45deg) translate(2.2px, -3px) scaleX(1.05);
            }
            &:after {
              transform: rotate(-45deg) translate(-2.2px, -3px) scaleX(1.05);
            }
          }
          &:last-child {
            &:before {
              transform: rotate(-45deg) translate(2.2px, 3px) scaleX(1.05);
            }
            &:after {
              transform: rotate(45deg) translate(-2.2px, 3px) scaleX(1.05);
            }
          }
        }
      }
      svg {
        stroke-dashoffset: 62;
        stroke-dasharray: 0 82.801 62 82.801;
        transform: rotate(90deg);
        stroke: $white;
        &:nth-child(3) {
          transform: rotate(270deg);
        }
      }
    }
  }
}
