@import '../foundation/variable';
@import '../foundation/mixin';

.l-footer {
  position: relative;
  padding-top: 60px;
  padding-bottom: 20px;
  background: $lightgray;
  .inner {
    @include tb {
      max-width: 560px;
    }
    max-width: 1200px;
  }
  &__f-logo {
    width: 100%;
    a {
      display: inline-block;
    }
    img {
      width: 120px;
      margin-left: 0;
      &:hover {
        opacity: 0.7;
      }
    }
  }
  &__top {
    @include tb {
      gap: 30px;
    }
    @include sp {
      gap: 20px 10px;
    }
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 8px;
    padding-bottom: 15px;
    margin-bottom: 10px;
    border-bottom: 1px solid #ddd;
    &-col {
      @include tb {
        flex-grow: 1;
        width: calc(100% / 3 - 24px);
      }
      @include sp {
        width: calc(50% - 10px);
      }
      &:nth-of-type(1) {
        @include sp {
          order: 1;
        }
      }
      &:nth-of-type(2) {
        @include tb {
          width: calc((100% / 3) * 2 - 12px);
        }
        @include sp {
          width: 100%;
          order: 0;
        }
      }
      &:nth-of-type(3) {
        @include sp {
          order: 2;
        }
      }
      &:nth-of-type(4) {
        @include sp {
          order: 3;
        }
      }
      &:nth-of-type(5) {
        @include sp {
          order: 4;
        }
      }
      .u-flex {
        @include sp {
          gap: 10px;
        }
        gap: 20px;
        ul {
          @include sp {
            width: calc(50% - 10px);
          }
        }
      }
      .sub-titl {
        position: relative;
        color: $black;
        display: flex;
        align-items: center;
        font-size: 12px;
        font-weight: 700;
        margin-bottom: 8px;
        &::after {
          content: '';
          display: inline-block;
          height: 1px;
          border-top: 1px solid #ddd;
          flex: 1;
          margin-left: 1em;
        }
        a {
          color: $black;
          text-decoration: none;
          &:hover {
            text-decoration: underline;
            color: $black;
          }
        }
      }
      .c-titl {
        color: $black;
        a {
          @include sp {
            font-size: 11px;
          }
          color: $black;
          font-size: 12px;
        }
      }
      ul {
        @include sp {
          gap: 2px;
        }
        display: flex;
        flex-direction: column;
        gap: 4px;
        li {
          list-style: none;
          a {
            @include sp {
              font-size: 11px;
            }
            font-size: 12px;
            color: #666;
            text-decoration: none;
            &:hover {
              text-decoration: underline 1px;
            }
          }
        }
      }
    }
  }
  &_certification {
    @include tb {
      justify-content: center;
    }
    width: 100%;
    display: flex;
    justify-content: flex-end;
    order: 100;
    &-flex {
      padding: 8px;
      display: inline-flex;
      gap: 30px;
      justify-content: flex-end;
      align-items: center;
      background: $white;
    }
    .privacy-mark {
      img {
        height: 60px;
      }
    }
    .iso-mark {
      img {
        height: 60px;
      }
    }
    a {
      &:hover {
        img {
          opacity: 0.7;
        }
      }
    }
  }
  &__bottom {
    @include tb {
      justify-content: center;
      gap: 6px;
    }
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    align-items: center;
    ul {
      @include tb {
        justify-content: center;
      }
      display: flex;
      gap: 10px;
      li {
        list-style: none;
        display: inline-flex;
        align-items: center;
        a {
          font-size: 10px;
          color: #666;
          text-decoration: none;
          &:hover {
            text-decoration: underline 1px;
          }
        }
        & + li::before {
          content: '';
          display: inline-flex;
          width: 1px;
          height: 80%;
          background: #666666;
          margin-right: 10px;
        }
      }
    }
    small {
      @include tb {
        margin: auto;
      }
      @include sp {
        letter-spacing: 0.08em;
      }
      margin-left: auto;
      font-size: 10px;
    }
  }
}
