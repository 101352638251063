@use 'variable';

// メディアクエリ
@mixin sp {
  @media (max-width: ($sp)) {
    @content;
  }
}
@mixin tb {
  @media (max-width: ($tb)) {
    @content;
  }
}
@mixin pc {
  @media (max-width: ($pc)) {
    @content;
  }
}
@mixin lg {
  @media (max-width: ($lg)) {
    @content;
  }
}
@mixin xlg {
  @media (max-width: ($xlg)) {
    @content;
  }
}
@mixin xxlg {
  @media (max-width: ($xxlg)) {
    @content;
  }
}
@mixin c-titl {
  position: relative;
  font-weight: 700;
  letter-spacing: 0.05em;
}