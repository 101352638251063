@import '../../foundation/variable';
@import '../../foundation/mixin';

@for $c from 10 through 100 {
  .p-card {
    &#{$c} {
      @include tb {
        padding: 1px * $c * 0.8;
      }
      padding: 1px * $c;
    }
    &#{$c}-r8 {
      @include tb {
        padding: 1px * $c * 0.8;
      }
      padding: 1px * $c;
      border-radius: 8px;
      background: #f5f5f5;
    }
    &#{$c}-r50 {
      @include sp {
        padding: 1px * $c * 0.8;
      }
      border-radius: 50px;
      padding: 1px * $c;
      background: #f5f5f5;
    }
    &#{$c}-r30 {
      @include sp {
        padding: 1px * $c * 0.8;
      }
      border-radius: 30px;
      padding: 1px * $c;
      background: #f5f5f5;
    }
    // &40 {
    //   @include sp {
    //     padding: 20px;
    //   }
    //   padding: 40px;
    //   background: #f5f5f5;
    //   &-r8 {
    //     @include sp {
    //       padding: 20px;
    //     }
    //     border-radius: 8px;
    //     padding: 40px;
    //     background: #f5f5f5;
    //   }
    // }
    // &30 {
    //   @include sp {
    //     padding: 20px;
    //   }
    //   padding: 30px;
    //   background: #f5f5f5;
    //   &-r8 {
    //     @include sp {
    //       padding: 20px;
    //     }
    //     border-radius: 8px;
    //     padding: 30px;
    //     background: #f5f5f5;
    //   }
    // }
    // &20 {
    //   @include sp {
    //     padding: 20px;
    //   }
    //   padding: 20px;
    //   background: #f5f5f5;
    //   &-r8 {
    //     @include sp {
    //       padding: 20px;
    //     }
    //     border-radius: 8px;
    //     padding: 20px;
    //     background: #f5f5f5;
    //   }
    // }
  }
  .p-box {
    &#{$c} {
      @include tb {
        padding: 1px * $c * 0.8;
      }
      padding: 1px * $c;
      border-style: solid;
      border-width: 8px;
      border-color: $lightgray;
    }
  }
}
.p-service {
  @include pc {
    padding: 80px 60px 60px;
  }
  @include tb {
    padding: 60px 40px 40px;
  }
  @include sp {
    border-radius: 8px;
    padding: 30px 20px;
  }
  border-radius: 30px;
  padding: 100px 80px 80px;
  background: #a0a0fb;
  &-flex {
    @include sp {
      gap: 20px;
    }
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    .col {
      @include pc {
        padding: 25px;
      }
      @include tb {
        padding: 20px;
      }
      @include sp {
        width: 100%;
      }
      width: calc(50% - 20px);
      padding: $col-padding;
      border-radius: $col-border-radius;
      box-shadow: $col-box-shadow;
      background: $white;
    }
  }
  .u-flex4,
  .u-flex3 {
    @include sp {
      gap: 15px;
    }
    gap: 30px;
    .col {
      @include sp {
        font-size: 18px;
        letter-spacing: 0.05em;
      }
      font-size: 20px;
      font-weight: 700;
    }
  }
  .u-flex4 {
    .col {
      @include tb {
        width: calc((100% / 2) - 15px);
      }
      @include sp {
        width: 100%;
      }
      width: calc(25% - 22.5px);
    }
  }
  .u-flex3 {
    .col {
      @include tb {
        width: calc((100% / 2) - 15px);
      }
      @include sp {
        width: 100%;
      }
      width: calc((100% / 3) - 22.5px);
    }
  }
  .c-balloon {
    @include tb {
      padding: 15px;
    }
    @include sp {
      border-radius: 8px 8px 0 8px;
      padding: 10px 10px;
      background: rgba(255, 255, 255, 0.85);
    }
    padding: 20px 1.3vw;
  }
  .txt-sub {
    @include sp {
      font-size: 11px;
    }
    font-size: 12px;
    line-height: 1.2;
    display: block;
    margin-top: 5px;
  }
}

.p-case {
  @include sp {
    padding: 20px 10px 30px;
    margin-bottom: 80px;
  }
  position: relative;
  padding: 40px 20px 60px;
  border-radius: 8px;
  background: #f5f5f5;
  margin-bottom: 100px;
  &::after {
    @include sp {
      width: 38px;
      height: 50px;
    }
    content: '';
    position: absolute;
    left: 50%;
    bottom: 0;
    display: block;
    width: 66px;
    height: 80px;
    transform: translate(-50%, 65%);
    background: url(../image/arr-bttm-main.png) no-repeat center / contain;
  }
  &-txt {
    @include tb {
      font-size: 20px;
    }
    @include sp {
      font-size: 18px;
    }
    color: $main;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 10px;
  }
  &-c-titl {
    font-weight: 700;
  }
}
