@import '../../foundation/variable';
@import '../../foundation/mixin';

.c-bttn {
  a {
    @include sp {
      min-width: 160px;
      padding: 1em 15px;
    }
    position: relative;
    padding: 1em 2.3em;
    color: $black;
    font-weight: 700;
    text-decoration: none;
    line-height: 1.2;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-style: solid;
    border-width: 3px;
    border-color: $black;
    background: $white;
    border-radius: 50px;
    letter-spacing: 0.1em;
    transition: 0.6s;
    &:hover {
      color: $white;
      background: $black;
    }
  }
  &-white {
    a {
      border-color: $white;
      border-color: $white;
      background-color: $white;
      &:hover {
        color: $white;
        border-color: $navy;
        background-color: $navy;
      }
    }
    &-outline {
      &-navy {
        a {
          background-color: $white;
          border-color: $navy;
          &:hover {
            border-color: $navy;
            background-color: $navy;
          }
        }
      }
    }
  }
  &-main {
    a {
      color: $white;
      border-color: $main;
      background-color: $main;
      &:hover {
        color: $main;
        border-color: $main;
        background-color: $white;
      }
    }
  }
  &-navy {
    a {
      color: $white !important;
      border-color: $navy;
      background-color: $navy;
      &:hover {
        color: $navy !important;
        border-color: $navy;
        background-color: $white;
      }
    }
  }
  &-purple {
    a {
      border-color: #a0a0fb;
      background: #a0a0fb;
      &:hover {
        color: #a0a0fb;
        border-color: #a0a0fb;
        background-color: $white;
      }
    }
  }
  &-black {
    a {
      color: $white;
      border-color: $black;
      background-color: $black;
      &:hover {
        color: $black;
        border-color: $black;
        background-color: $white;
      }
    }
  }
  &-outline {
    a {
      background-color: transparent;
      &:hover {
        border-color: $black;
        background-color: $black;
      }
    }
    &-main {
      a {
        background-color: transparent;
        border-color: $main;
        &:hover {
          border-color: $main;
          background-color: $main;
        }
      }
    }
    &-navy {
      a {
        background-color: transparent;
        border-color: $navy;
        &:hover {
          border-color: $navy;
          background-color: $navy;
        }
      }
    }
  }
  &-xs {
    a {
      padding: 0.5em;
      font-size: 13px;
    }
  }
  &-sm {
    a {
      padding: 0.65em 1em;
      font-size: 13px;
    }
  }
  &-lg {
    a {
      @include sp {
        font-size: 16px;
      }
      font-size: 18px;
    }
  }
  &-entry {
    a {
      min-width: 250px;
      font-size: 18px;
    }
  }
  &-arr {
    a {
      padding: 0.8em 2em 0.8em 1.5em;
      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        right: 1em;
        width: 0.5em;
        height: 0.5em;
        border-style: solid;
        border-color: $currentColor;
        border-width: 2px 2px 0 0;
        transform: translateY(-50%) rotate(45deg);
      }
    }
  }
  &-anchor {
    a {
      padding: 0.8em 2em 0.8em 1.5em;
      &::after {
        content: '';
        display: block;
        position: absolute;
        top: calc(50% - 0.1em);
        right: 1em;
        width: 0.5em;
        height: 0.5em;
        border-style: solid;
        border-color: $currentColor;
        border-width: 0 2px 2px 0;
        transform: translateY(-50%) rotate(45deg);
      }
    }
  }
}
