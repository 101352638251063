@import '../../foundation/variable';
@import '../../foundation/mixin';

.c-subnavi {
  & + .p-cv.-sub {
    margin-top: 0;
    &::after {
      background: $navy;
      height: 100%;
    }
  }
  @include sp {
    padding: 20px 0 60px;
  }
  padding: 40px 0;
  background: $navy;
  .inner {
    @include sp {
      flex-direction: column;
      gap: 0;
    }
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    gap: 30px;
  }
  &_title {
    a {
      @include sp {
        gap: 0;
      }
      position: relative;
      padding: 20px;
      display: flex;
      align-items: center;
      gap: 15px;
      text-decoration: none;
      color: $white;
      &::before {
        @include sp {
          display: none;
        }
        content: '';
        display: inline-block;
        width: 60px;
        height: 1px;
        background: rgba(255, 255, 255, 0.5);
      }
      &:hover {
        text-decoration: underline;
      }
    }
  }
  ul {
    @include sp {
      width: 180px;
      gap: 15px;
      flex-direction: column;
    }
    display: flex;
    gap: 30px;
    li.c-bttn {
      list-style-type: none;
      a {
        @include sp {
          width: 100%;
        }
        border-color: transparent;
      }
      &.current {
        a {
          background: $purple !important;
          &:hover {
            color: $white;
            background: $black !important;
          }
        }
      }
    }
  }
}
