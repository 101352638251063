@import 'foundation/variable';
@import 'foundation/mixin';

.page_index {
  background: #f5f5f5;
}
.pagetitle {
  @include sp {
    padding-top: 15px;
  }
  padding-top: 30px;
  &__inner {
    @include tb {
      flex-direction: column;
      gap: 20px;
    }
    @include sp {
      gap: 10px;
    }
    max-width: 1380px;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 40px;
    margin-left: auto;
    margin-right: auto;
  }
  &__titlarea {
    @include tb {
      width: 100%;
      flex: initial;
    }
    flex: 1;
  }
  &__c-title {
    @include tb {
      text-align: center;
      font-size: 36px;
    }
    @include sp {
      font-size: 26px;
    }
    font-size: 40px;
    line-height: 1.2;
  }
  &__txt {
    @include sp {
      margin-top: 0.5em;
    }
    margin-top: 1em;
    margin-bottom: 0;
  }
}
#locator {
  @include sp {
    margin-bottom: 40px;
  }
  margin-bottom: 80px;
  ol {
    @include tb {
      justify-content: flex-start;
    }
    @include sp {
      flex-wrap: nowrap;
      overflow-x: scroll;
      padding-bottom: 8px;
    }
    margin-top: 15px;
    padding-top: 15px;
    border-top: 1px solid #333;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    gap: 8px 20px;
    li {
      @include sp {
        white-space: nowrap;
      }
      position: relative;
      display: inline-block;
      font-size: 11px;
      line-height: 1;
      list-style: none;
      &:last-child::after {
        display: none;
      }
      &::after {
        content: '';
        position: absolute;
        top: 0px;
        right: -20px;
        display: inline-block;
        width: 1px;
        height: 1em;
        background: #ccc;
        margin: 0 10px;
      }
      // & + li::before {
      //   content: '';
      //   display: inline-block;
      //   width: 1px;
      //   height: 80%;
      //   background: #ccc;
      //   margin-right: 10px;
      // }
      a {
        color: #ccc;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
#sitemap {
  display: flex;
  flex-wrap: wrap;
  gap: 60px;
  li {
    display: flex;
    flex-direction: column;
    gap: 12px;
    list-style-type: none;
    a {
      text-decoration: none;
      color: $black;
      font-weight: 700;
      transition: 0.3s;
      &:hover {
        text-decoration: underline;
      }
    }
    ul {
      display: flex;
      flex-direction: column;
      gap: 6px;
      li {
        position: relative;
        padding-left: 1em;
        &::before {
          content: '';
          display: block;
          position: absolute;
          top: 6px;
          left: 0;
          width: 8px;
          height: 8px;
          border-top: 2px solid $main;
          border-right: 2px solid $main;
          transform: rotate(45deg);
        }
        a {
          font-weight: 500;
        }
      }
    }
  }
  .en-sitemap {
    > ul {
      // flex-direction: row !important;
      // flex-wrap: wrap;
      gap: 30px;
      > li {
        padding-left: 0;
        &::before {
          display: none;
        }
        a {
          text-decoration: none;
          color: $black;
          font-weight: 700;
        }
      }
    }
  }
}
