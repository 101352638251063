@import '../../foundation/variable';
@import '../../foundation/mixin';

// Background Color
@each $key, $value in $original-color {
  .bg-#{$key} {
    background-color: $value $imp;
  }
  @include tb {
    .bg-tb-#{$key} {
      background-color: $value $imp;
    }
  }
  @include sp {
    .bg-sp-#{$key} {
      background-color: $value $imp;
    }
  }
}

@for $i from 10 through 100 {
  $ci: $i / 100;
  .bg {
    &-main-#{$i}pct {
      background-color: rgba(87, 79, 194, $ci) + $imp;
    }
    &-black-#{$i}pct {
      background-color: rgba(52, 58, 64, $ci) + $imp;
    }
    &-white-#{$i}pct {
      background-color: rgba(255, 255, 255, $ci) + $imp;
    }
    &-red-#{$i}pct {
      background-color: rgba(239, 80, 114, $ci) + $imp;
    }
    &-blue-#{$i}pct {
      background-color: rgba(117, 177, 237, $ci) + $imp;
    }
    &-purple-#{$i}pct {
      background-color: rgba(160, 160, 251, $ci) + $imp;
    }
    &-grn-#{$i}pct {
      background-color: rgba(63, 224, 168, $ci) + $imp;
    }
    &-navy-#{$i}pct {
      background-color: rgba(50, 44, 144, $ci) + $imp;
    }
    &-gray-#{$i}pct {
      background-color: rgba(108, 117, 125, $ci) + $imp;
    }
    @include tb {
      &-tb {
        &-main-#{$i}pct {
          background-color: rgba(87, 79, 194, $ci) + $imp;
        }
        &-black-#{$i}pct {
          background-color: rgba(52, 58, 64, $ci) + $imp;
        }
        &-white-#{$i}pct {
          background-color: rgba(255, 255, 255, $ci) + $imp;
        }
        &-red-#{$i}pct {
          background-color: rgba(239, 80, 114, $ci) + $imp;
        }
        &-blue-#{$i}pct {
          background-color: rgba(117, 177, 237, $ci) + $imp;
        }
        &-purple-#{$i}pct {
          background-color: rgba(160, 160, 251, $ci) + $imp;
        }
        &-grn-#{$i}pct {
          background-color: rgba(63, 224, 168, $ci) + $imp;
        }
        &-navy-#{$i}pct {
          background-color: rgba(50, 44, 144, $ci) + $imp;
        }
        &-gray-#{$i}pct {
          background-color: rgba(108, 117, 125, $ci) + $imp;
        }
      }
    }
    @include sp {
      &-sp {
        &-main-#{$i}pct {
          background-color: rgba(87, 79, 194, $ci) + $imp;
        }
        &-black-#{$i}pct {
          background-color: rgba(52, 58, 64, $ci) + $imp;
        }
        &-white-#{$i}pct {
          background-color: rgba(255, 255, 255, $ci) + $imp;
        }
        &-red-#{$i}pct {
          background-color: rgba(239, 80, 114, $ci) + $imp;
        }
        &-blue-#{$i}pct {
          background-color: rgba(117, 177, 237, $ci) + $imp;
        }
        &-purple-#{$i}pct {
          background-color: rgba(160, 160, 251, $ci) + $imp;
        }
        &-grn-#{$i}pct {
          background-color: rgba(63, 224, 168, $ci) + $imp;
        }
        &-navy-#{$i}pct {
          background-color: rgba(50, 44, 144, $ci) + $imp;
        }
        &-gray-#{$i}pct {
          background-color: rgba(108, 117, 125, $ci) + $imp;
        }
      }
    }
  }
}
