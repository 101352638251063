@import '../../foundation/variable';
@import '../../foundation/mixin';

.rss_box {
  dl {
    @include sp {
      grid-template-columns: 1fr;
    }
    display: grid;
    grid-template-columns: 127px 1fr;
    grid-template-rows: auto;
    gap: 10px 30px;
    & + dl {
      @include sp {
        padding-top: 20px;
        margin-top: 20px;
      }
      padding-top: 30px;
      margin-top: 30px;
      border-top: 1px solid #ddd;
    }
    &:hover {
    }
  }
  .rss_date {
    @include sp {
      flex-direction: row;
      align-items: center;
      grid-row: 1 / 2;
    }
    font-size: 18px;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    gap: 8px;
    grid-column: 1 / 2;
    grid-row: 1 / 3;
  }
  .rss_span {
    width: 80px;
    padding: 5px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    line-height: 1;
    border: 2px solid $main;
    border-radius: 50px;
  }
  .rss_title {
    @include sp {
      grid-column: 1 / 2;
      grid-row: 2 / 3;
    }
    grid-column: 2 / 3;
    grid-row: 1 / 2;
    a {
      display: inline-block;
      font-size: 20px;
      font-weight: 500;
      text-decoration: none;
      color: $black;
      &:hover {
        text-decoration: underline 1px;
      }
    }
  }
  .rss_contents {
    @include sp {
      grid-column: 1 / 2;
      grid-row: 3 / 4;
    }
    grid-column: 2 / 3;
    grid-row: 2 / 3;
    display: none;
  }
  &.--index {
    dl {
      @include sp {
        flex-direction: column;
        gap: 10px;
      }
      display: flex;
      align-items: center;
      gap: 30px;
    }
    .rss_date {
      @include sp {
        width: 100%;
      }
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .rss_title {
      @include sp {
        width: 100%;
        flex: initial;
      }
      flex: 1;
      a {
        font-size: 16px;
      }
    }
  }
  &.--sub {
    .rss_contents {
      display: block;
    }
    .rss_title {
      a {
        pointer-events: none;
        cursor: none;
      }
    }
  }
}
.c-data-link {
  dl {
    @include xxlg {
      flex-direction: column;
    }
    @include tb {
      width: 100%;
      gap: 20px;
    }
    position: relative;
    padding: 15px 3.5vw;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 40px;
    border-style: solid;
    border-width: 8px;
    border-color: $main;
    &::before {
      @include pc {
        width: calc(100% - 20px);
      }
      @include tb {
        width: calc(100% - 100px);
      }
      @include sp {
        width: calc(100% - 50px);
      }
      content: '';
      display: block;
      width: calc(100% - 80px);
      height: calc(100% + 30px);
      position: absolute;
      top: -15px;
      left: 50%;
      background: $white;
      transform: translateX(-50%);
    }
    dt {
      @include pc {
        flex: initial;
        width: 100%;
      }
      position: relative;
      flex: 1;
    }
    dd {
      @include pc {
        margin: auto;
      }
      position: relative;
      max-width: 340px;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
    .c-leadtxt {
      @include sp {
      }
    }
    .c-bttn {
      width: 100%;
      a {
        @include sp {
          padding: 1em 0;
        }
        width: 100%;
        padding: 1em 2em;
      }
    }
  }
  &.--sub {
    position: relative;
    dl {
      padding-top: 40px;
    }
    .c-titl {
      position: absolute;
      top: 0;
      left: 50%;
      width: 90%;
      text-align: center;
      transform: translate(-50%, -50%);
      z-index: 1;
    }
  }
  &.color-red {
    dl {
      border-color: $red;
    }
  }
  &.color-grn {
    dl {
      border-color: $grn;
    }
  }
  &.color-blue {
    dl {
      border-color: $blue;
    }
  }
}

// FAQ
.faqList {
  &.color-red {
    dl {
      dt {
        &::before {
          color: $red;
        }
      }
    }
  }
  &.color-grn {
    dl {
      dt {
        &::before {
          color: $grn;
        }
      }
    }
  }
  &.color-blue {
    dl {
      dt {
        &::before {
          color: $blue;
        }
      }
    }
  }
  & + .faqList {
    padding-top: 20px;
    border-top: 1px solid #ddd;
  }
  dl {
    display: flex;
    flex-direction: column;
    gap: 20px;
    dt {
      position: relative;
      font-size: 24px;
      font-weight: 500;
      line-height: 1.4;
      padding-left: 40px;
      &::before {
        content: 'Q';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        font-size: 32px;
        font-weight: 900;
        line-height: 1;
        color: $main;
      }
    }
    dd {
      font-weight: 400;
      padding-left: 40px;
    }
  }
}
.dl-list {
  display: flex;
  flex-wrap: wrap;
  dt {
    font-weight: 500;
  }
  dd {
    flex: 1;
  }
}
.dl-case {
  @include sp {
    flex-direction: column;
  }
  display: flex;
  flex-wrap: wrap;
  & + .dl-case {
    margin-top: 20px;
  }
  dt {
    @include sp {
      width: 100%;
      padding: 10px;
      justify-content: center;
    }
    width: 120px;
    padding: 20px;
    color: $main;
    display: flex;
    align-items: center;
    font-weight: 700;
    background: #dbdefd;
  }
  dd {
    @include tb {
      font-size: 15px;
    }
    @include sp {
      width: 100%;
      padding: 10px 0;
      background: transparent;
    }
    width: calc(100% - 120px);
    padding: 20px;
    font-size: 18px;
    background: $lightgray;
  }
}
