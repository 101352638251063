@import '../../foundation/variable';
@import '../../foundation/mixin';

// margin auto
@include tb {
  .m-auto-tb {
    margin-left: auto;
    margin-right: auto;
  }
}
@include sp {
  .m-auto-sp {
    margin-left: auto;
    margin-right: auto;
  }
}
.m-auto {
  margin-left: auto;
  margin-right: auto;
}

@for $i from 0 through 200 {
  .m#{$i} {
    margin: 1px * $i;
  }
  .mt#{$i} {
    margin-top: 1px * $i;
  }
  .mr#{$i} {
    margin-right: 1px * $i;
  }
  .mb#{$i} {
    margin-bottom: 1px * $i;
  }
  .ml#{$i} {
    margin-left: 1px * $i;
  }
  .my#{$i} {
    margin-top: 1px * $i;
    margin-bottom: 1px * $i;
  }
  .mx#{$i} {
    margin-right: 1px * $i;
    margin-left: 1px * $i;
  }
  @include tb {
    .m#{$i}-tb {
      margin: 1px * $i + $imp;
    }
    .mt#{$i}-tb {
      margin-top: 1px * $i + $imp;
    }
    .mr#{$i}-tb {
      margin-right: 1px * $i + $imp;
    }
    .mb#{$i}-tb {
      margin-bottom: 1px * $i + $imp;
    }
    .ml#{$i}-tb {
      margin-left: 1px * $i + $imp;
    }
    .my#{$i}-tb {
      margin-top: 1px * $i + $imp;
      margin-bottom: 1px * $i + $imp;
    }
    .mx#{$i}-tb {
      margin-right: 1px * $i + $imp;
      margin-left: 1px * $i + $imp;
    }
  }
  @include sp {
    .m#{$i}-sp {
      margin: 1px * $i + $imp;
    }
    .mt#{$i}-sp {
      margin-top: 1px * $i + $imp;
    }
    .mr#{$i}-sp {
      margin-right: 1px * $i + $imp;
    }
    .mb#{$i}-sp {
      margin-bottom: 1px * $i + $imp;
    }
    .ml#{$i}-sp {
      margin-left: 1px * $i + $imp;
    }
    .my#{$i}-sp {
      margin-top: 1px * $i + $imp;
      margin-bottom: 1px * $i + $imp;
    }
    .mx#{$i}-sp {
      margin-right: 1px * $i + $imp;
      margin-left: 1px * $i + $imp;
    }
  }
}
