@import '../../foundation/variable';
@import '../../foundation/mixin';

// Font size
@for $i from 10 through 100 {
  .fz-#{$i} {
    font-size: 1px * $i;
  }
  @include tb {
    .fz-tb-#{$i} {
      font-size: 1px * $i + $imp;
    }
  }
  @include sp {
    .fz-sp-#{$i} {
      font-size: 1px * $i + $imp;
    }
  }
}

// Line height
@for $i from 10 through 30 {
  $ci: $i / 10;
  .lineheight {
    &-#{$i} {
      line-height: 1 * $ci;
    }
    @include tb {
      &-tb {
        &-#{$i} {
          line-height: 1 * $ci $imp;
        }
      }
    }
    @include sp {
      &-sp {
        &-#{$i} {
          line-height: 1 * $ci $imp;
        }
      }
    }
  }
}

// Text align
.txt {
  &-center {
    text-align: center $imp;
  }
  &-right {
    text-align: right $imp;
  }
  &-left {
    text-align: left $imp;
  }
  @include tb {
    &-tb {
      &-center {
        text-align: center $imp;
      }
      &-right {
        text-align: right $imp;
      }
      &-left {
        text-align: left $imp;
      }
    }
  }
  @include sp {
    &-sp {
      &-center {
        text-align: center $imp;
      }
      &-right {
        text-align: right $imp;
      }
      &-left {
        text-align: left $imp;
      }
    }
  }
}

// Font weight
.fw {
  &-bold {
    font-weight: bold $imp;
  }
  &-nomal {
    font-weight: normal $imp;
  }
  &-100 {
    font-weight: 100 $imp;
  }
  &-200 {
    font-weight: 200 $imp;
  }
  &-300 {
    font-weight: 300 $imp;
  }
  &-400 {
    font-weight: 400 $imp;
  }
  &-500 {
    font-weight: 500 $imp;
  }
  &-600 {
    font-weight: 600 $imp;
  }
  &-700 {
    font-weight: 700 $imp;
  }
  &-800 {
    font-weight: 800 $imp;
  }
  &-900 {
    font-weight: 900 $imp;
  }
  @include tb {
    &-tb {
      &-bold {
        font-weight: bold $imp;
      }
      &-nomal {
        font-weight: normal $imp;
      }
      &-100 {
        font-weight: 100 $imp;
      }
      &-200 {
        font-weight: 200 $imp;
      }
      &-300 {
        font-weight: 300 $imp;
      }
      &-400 {
        font-weight: 400 $imp;
      }
      &-500 {
        font-weight: 500 $imp;
      }
      &-600 {
        font-weight: 600 $imp;
      }
      &-700 {
        font-weight: 700 $imp;
      }
      &-800 {
        font-weight: 800 $imp;
      }
      &-900 {
        font-weight: 900 $imp;
      }
    }
  }
  @include sp {
    &-sp {
      &-bold {
        font-weight: bold $imp;
      }
      &-nomal {
        font-weight: normal $imp;
      }
      &-100 {
        font-weight: 100 $imp;
      }
      &-200 {
        font-weight: 200 $imp;
      }
      &-300 {
        font-weight: 300 $imp;
      }
      &-400 {
        font-weight: 400 $imp;
      }
      &-500 {
        font-weight: 500 $imp;
      }
      &-600 {
        font-weight: 600 $imp;
      }
      &-700 {
        font-weight: 700 $imp;
      }
      &-800 {
        font-weight: 800 $imp;
      }
      &-900 {
        font-weight: 900 $imp;
      }
    }
  }
}

// Font color
@each $key, $value in $original-color {
  .txt-#{$key} {
    color: $value $imp;
  }
  @include tb {
    .txt-tb-#{$key} {
      color: $value $imp;
    }
  }
  @include sp {
    .txt-sp-#{$key} {
      color: $value $imp;
    }
  }
}
