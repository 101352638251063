@import '../../foundation/variable';
@import '../../foundation/mixin';

.p-cv {
  @include sp {
    margin-top: 100px;
  }
  position: relative;
  margin-top: 120px;
  @include pc {
  }
  .inner {
    @include pc {
      padding: 50px 30px;
    }
    @include sp {
      padding: 50px 30px 30px;
    }
    position: relative;
    border-radius: 8px;
    padding: 50px;
    background: #e6e6e6;
    // overflow: hidden;
    z-index: 1;
    &::after {
      @include lg {
        display: block;
      }
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      display: none;
      width: 54px;
      height: 63px;
      background: url(/dcms_media/image/img_cube.svg) no-repeat center/contain;
      transform: translate(-50%, -50%);
    }
  }
  &.-sub {
    .inner {
      &::after {
        @include lg {
          display: none;
        }
      }
    }
    &::before {
      background: $white;
    }
    &::after {
      background: $lightgray;
    }
  }
  &::before {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background: $lightgray;
    z-index: 0;
  }
  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 200%;
    background: $white;
    z-index: -10;
  }
  &-bgimg {
    @include xlg {
      width: 12vw;
    }
    @include lg {
      display: none;
    }
    position: absolute;
    left: 0;
    bottom: 0;
    width: 16vw;
  }
  &-inngrid {
    @include lg {
      gap: 7px 20px;
    }
    @include pc {
      grid-template-columns: repeat(2, 1fr);
    }
    @include tb {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 15px;
    }
    position: relative;
    display: grid;
    grid-template-columns: 340px 1fr;
    grid-template-rows: auto;
    gap: 8px 60px;
    margin-left: auto;
    max-width: 988px;
    z-index: 10;
  }
  &__c-titl {
    @include pc {
      font-size: 48px;
      letter-spacing: 0.1em;
    }
    @include tb {
      font-size: 48px;
      text-align: center;
      // letter-spacing: 0.2em;
    }
    @include sp {
      font-size: 28px;
      font-weight: 500;
      letter-spacing: 0.1em;
    }
    font-size: 48px;
    font-weight: 400;
    line-height: 1;
    grid-column: 1/2;
    grid-row: 1/2;
    letter-spacing: 0.2em;
  }
  &__leadtxt {
    @include pc {
      font-size: 10px;
    }
    @include tb {
      font-size: 14px;
      text-align: center;
    }
    @include sp {
      text-align: left;
    }
    grid-column: 1 / 2;
    grid-row: 2 / 3;
  }
  &__bttn-list {
    @include sp {
      flex-direction: column;
    }
    width: 100%;
    grid-column: 2/3;
    grid-row: 1/2;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 14px;
    .c-bttn {
      @include sp {
        width: auto;
        min-width: 240px;
      }
      width: calc(50% - 7px);
      a {
        width: 100%;
        padding: 1em;
      }
    }
  }
  &__info {
    @include tb{
      align-self: center;
    }
    @include sp {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 6px;
    }
    grid-column: 2/3;
    grid-row: 2/3;
    display: grid;
    grid-template-columns: 170px 1fr;
    grid-template-rows: auto;
    gap: 0 20px;
    align-self: flex-start;
    &-titl {
      grid-column: 1/2;
      grid-row: 1/2;
      font-size: 13px;
      font-weight: 500;
      align-self: center;
      //   letter-spacing: 0.03em;
    }
    &-time {
      @include sp {
        padding: 5px 20px;
        align-self: auto;
        font-size: 11px;
      }
      grid-column: 1/2;
      grid-row: 2/3;
      padding: 5px;
      font-size: 13px;
      border: 1px solid #343a40;
      display: flex;
      align-items: center;
      justify-content: center;
      align-self: flex-start;
      white-space: nowrap;
    }
    &-tel {
      grid-column: 2/3;
      grid-row: 1/3;
      a {
        @include lg {
          font-size: 4.9vw;
        }
        @include pc {
          font-size: 60px;
        }
        @include tb {
          font-size: 50px;
          font-weight: 500;
          line-height: 0.8;
          pointer-events: auto;
        }
        @include sp {
          font-size: 38px;
          font-weight: 500;
          line-height: 1;
        }
        color: $black;
        font-size: 60px;
        font-weight: 400;
        line-height: 1;
        white-space: nowrap;
        text-decoration: none;
        pointer-events: none;
      }
    }
  }
}
